/* Ignore prepended by codegen.yml */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useFetchData } from './Fetcher'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  JSONString: { input: any; output: any }
}

export type ClusterConnection = {
  __typename?: 'ClusterConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClusterEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type ClusterDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type ClusterDeletePayload = {
  __typename?: 'ClusterDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok?: Maybe<Scalars['Boolean']['output']>
}

/** A Relay edge containing a `Cluster` and its cursor. */
export type ClusterEdge = {
  __typename?: 'ClusterEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ClusterType>
}

export type ClusterInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type ClusterPayload = {
  __typename?: 'ClusterPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  cluster?: Maybe<ClusterType>
}

export type ClusterType = Node & {
  __typename?: 'ClusterType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  masterNodes: HardwareTypeConnection
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  workerNodes: HardwareTypeConnection
}

export type ClusterTypeMasterNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  hardwareModel_ModelName_Icontains?: InputMaybe<Scalars['String']['input']>
  isHealthy?: InputMaybe<Scalars['Boolean']['input']>
  isQuarantined?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  slug_Icontains?: InputMaybe<Scalars['String']['input']>
  team_Name_Icontains?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ClusterTypeWorkerNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  hardwareModel_ModelName_Icontains?: InputMaybe<Scalars['String']['input']>
  isHealthy?: InputMaybe<Scalars['Boolean']['input']>
  isQuarantined?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  slug_Icontains?: InputMaybe<Scalars['String']['input']>
  team_Name_Icontains?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ClusterTypeConnection = {
  __typename?: 'ClusterTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClusterTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `ClusterType` and its cursor. */
export type ClusterTypeEdge = {
  __typename?: 'ClusterTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ClusterType>
}

export type ContainerHardwareRequirementsConnection = {
  __typename?: 'ContainerHardwareRequirementsConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerHardwareRequirementsEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ContainerHardwareRequirements` and its cursor. */
export type ContainerHardwareRequirementsEdge = {
  __typename?: 'ContainerHardwareRequirementsEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ContainerHardwareRequirementsType>
}

export type ContainerHardwareRequirementsType = Node & {
  __typename?: 'ContainerHardwareRequirementsType'
  amountOfHardwareModels: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  hardwareModel: HardwareModelType
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type ContainerHardwareRequirementsTypeConnection = {
  __typename?: 'ContainerHardwareRequirementsTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerHardwareRequirementsTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `ContainerHardwareRequirementsType` and its cursor. */
export type ContainerHardwareRequirementsTypeEdge = {
  __typename?: 'ContainerHardwareRequirementsTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ContainerHardwareRequirementsType>
}

/** An enumeration. */
export enum ContainerRuntimeRequirementsAvailability {
  /** AVAILABLE */
  Available = 'AVAILABLE',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** PREVIEW */
  Preview = 'PREVIEW',
}

export type ContainerRuntimeRequirementsConnection = {
  __typename?: 'ContainerRuntimeRequirementsConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerRuntimeRequirementsEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ContainerRuntimeRequirements` and its cursor. */
export type ContainerRuntimeRequirementsEdge = {
  __typename?: 'ContainerRuntimeRequirementsEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ContainerRuntimeRequirementsType>
}

export type ContainerRuntimeRequirementsType = Node & {
  __typename?: 'ContainerRuntimeRequirementsType'
  availability: ContainerRuntimeRequirementsAvailability
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Human-readable description of the tags */
  label: Scalars['String']['output']
  /** Resource for the runtime, ex. tenstorrent.com/n300 */
  resourceName: Scalars['String']['output']
}

export type ContainerRuntimeRequirementsTypeConnection = {
  __typename?: 'ContainerRuntimeRequirementsTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContainerRuntimeRequirementsTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `ContainerRuntimeRequirementsType` and its cursor. */
export type ContainerRuntimeRequirementsTypeEdge = {
  __typename?: 'ContainerRuntimeRequirementsTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ContainerRuntimeRequirementsType>
}

export type CreateReservationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  requestedAmountOfHardwareModel?: InputMaybe<Scalars['Int']['input']>
  requestedHardwareModelId?: InputMaybe<Scalars['ID']['input']>
  requestedHardwareSlug?: InputMaybe<Scalars['String']['input']>
  requestedHardwareTagsIds?: InputMaybe<Array<Scalars['ID']['input']>>
  teamId: Scalars['ID']['input']
}

export type CreateReservationPayload = {
  __typename?: 'CreateReservationPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  reservation?: Maybe<ReservationType>
}

export type DeleteTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  key?: InputMaybe<Scalars['ID']['input']>
}

export type DeleteTokenPayload = {
  __typename?: 'DeleteTokenPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type FileSystemConnection = {
  __typename?: 'FileSystemConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FileSystemEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type FileSystemCreateInput = {
  capacity: Scalars['String']['input']
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  teamId: Scalars['String']['input']
}

export type FileSystemCreatePayload = {
  __typename?: 'FileSystemCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  fileSystem?: Maybe<FileSystemType>
}

export type FileSystemDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type FileSystemDeletePayload = {
  __typename?: 'FileSystemDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  fileSystem?: Maybe<FileSystemType>
}

/** A Relay edge containing a `FileSystem` and its cursor. */
export type FileSystemEdge = {
  __typename?: 'FileSystemEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<FileSystemType>
}

/** An enumeration. */
export enum FileSystemStatus {
  /** CREATING */
  Creating = 'CREATING',
  /** DELETED */
  Deleted = 'DELETED',
  /** ERROR */
  Error = 'ERROR',
  /** READY */
  Ready = 'READY',
  /** REQUEST_CREATE */
  RequestCreate = 'REQUEST_CREATE',
  /** REQUEST_DELETE */
  RequestDelete = 'REQUEST_DELETE',
}

export type FileSystemType = Node & {
  __typename?: 'FileSystemType'
  capacity: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<UserType>
  errorMessage?: Maybe<Scalars['String']['output']>
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  status: FileSystemStatus
  team?: Maybe<TeamType>
  updatedAt: Scalars['DateTime']['output']
}

export type FileSystemTypeConnection = {
  __typename?: 'FileSystemTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FileSystemTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `FileSystemType` and its cursor. */
export type FileSystemTypeEdge = {
  __typename?: 'FileSystemTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<FileSystemType>
}

export type GenerateKubeconfigForClusterInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  clusterId?: InputMaybe<Scalars['String']['input']>
}

export type GenerateKubeconfigForClusterPayload = {
  __typename?: 'GenerateKubeconfigForClusterPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  kubeconfig?: Maybe<Scalars['String']['output']>
}

export type GroupType = Node & {
  __typename?: 'GroupType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  userSet: UserTypeConnection
}

export type GroupTypeUserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  username_Icontains?: InputMaybe<Scalars['String']['input']>
}

export type HardwareConnection = {
  __typename?: 'HardwareConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HardwareEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type HardwareDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type HardwareDeletePayload = {
  __typename?: 'HardwareDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok?: Maybe<Scalars['Boolean']['output']>
}

/** A Relay edge containing a `Hardware` and its cursor. */
export type HardwareEdge = {
  __typename?: 'HardwareEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<HardwareType>
}

export type HardwareInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type HardwareModelConnection = {
  __typename?: 'HardwareModelConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HardwareModelEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `HardwareModel` and its cursor. */
export type HardwareModelEdge = {
  __typename?: 'HardwareModelEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<HardwareModelType>
}

export type HardwareModelType = Node & {
  __typename?: 'HardwareModelType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  manufacturer?: Maybe<ManufacturerType>
  modelIdentifier: Scalars['String']['output']
  modelName: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type HardwareModelTypeConnection = {
  __typename?: 'HardwareModelTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HardwareModelTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `HardwareModelType` and its cursor. */
export type HardwareModelTypeEdge = {
  __typename?: 'HardwareModelTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<HardwareModelType>
}

export type HardwarePayload = {
  __typename?: 'HardwarePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  hardware?: Maybe<HardwareType>
}

export type HardwareTagConnection = {
  __typename?: 'HardwareTagConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HardwareTagEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `HardwareTag` and its cursor. */
export type HardwareTagEdge = {
  __typename?: 'HardwareTagEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<HardwareTagType>
}

export type HardwareTagType = Node & {
  __typename?: 'HardwareTagType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type HardwareTagTypeConnection = {
  __typename?: 'HardwareTagTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HardwareTagTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `HardwareTagType` and its cursor. */
export type HardwareTagTypeEdge = {
  __typename?: 'HardwareTagTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<HardwareTagType>
}

export type HardwareType = Node & {
  __typename?: 'HardwareType'
  availableForReservation?: Maybe<Scalars['Boolean']['output']>
  awxHostDetailsUrl?: Maybe<Scalars['String']['output']>
  awxHostJobsUrl?: Maybe<Scalars['String']['output']>
  canBeReserved: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  dnsRecord: Scalars['String']['output']
  hardwareModel?: Maybe<HardwareModelType>
  /** The ID of the object */
  id: Scalars['ID']['output']
  isHealthy: Scalars['Boolean']['output']
  isProvisioning: Scalars['Boolean']['output']
  isQuarantined: Scalars['Boolean']['output']
  isReserved: Scalars['Boolean']['output']
  macAddress?: Maybe<Scalars['String']['output']>
  parentId?: Maybe<Scalars['String']['output']>
  pk?: Maybe<Scalars['String']['output']>
  serialNumber: Scalars['String']['output']
  slug: Scalars['String']['output']
  teamName?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type HardwareTypeConnection = {
  __typename?: 'HardwareTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HardwareTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `HardwareType` and its cursor. */
export type HardwareTypeEdge = {
  __typename?: 'HardwareTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<HardwareType>
}

export type IngressRouteAssignInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  workloadRunId?: InputMaybe<Scalars['ID']['input']>
}

export type IngressRouteAssignPayload = {
  __typename?: 'IngressRouteAssignPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type IngressRouteConnection = {
  __typename?: 'IngressRouteConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IngressRouteEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type IngressRouteCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  subdomain: Scalars['String']['input']
  workloadDefinitionId: Scalars['ID']['input']
}

export type IngressRouteCreatePayload = {
  __typename?: 'IngressRouteCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type IngressRouteDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  workloadDefinitionId: Scalars['ID']['input']
}

export type IngressRouteDeletePayload = {
  __typename?: 'IngressRouteDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

/** A Relay edge containing a `IngressRoute` and its cursor. */
export type IngressRouteEdge = {
  __typename?: 'IngressRouteEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<IngressRouteType>
}

export type IngressRouteType = Node & {
  __typename?: 'IngressRouteType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
  workloadRun?: Maybe<WorkloadRunType>
}

export type IngressRouteTypeConnection = {
  __typename?: 'IngressRouteTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IngressRouteTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `IngressRouteType` and its cursor. */
export type IngressRouteTypeEdge = {
  __typename?: 'IngressRouteTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<IngressRouteType>
}

export type KubeconfigType = {
  __typename?: 'KubeconfigType'
  kubeconfig: Scalars['String']['output']
}

export type LoginInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type LoginPayload = {
  __typename?: 'LoginPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  token?: Maybe<TokenType>
}

export type LogoutInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  confirm: Scalars['Boolean']['input']
}

export type LogoutPayload = {
  __typename?: 'LogoutPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type ManufacturerConnection = {
  __typename?: 'ManufacturerConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ManufacturerEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `Manufacturer` and its cursor. */
export type ManufacturerEdge = {
  __typename?: 'ManufacturerEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ManufacturerType>
}

export type ManufacturerType = Node & {
  __typename?: 'ManufacturerType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  cluster?: Maybe<ClusterPayload>
  clusterDelete?: Maybe<ClusterDeletePayload>
  createReservation?: Maybe<CreateReservationPayload>
  deleteToken?: Maybe<DeleteTokenPayload>
  fileSystemCreate?: Maybe<FileSystemCreatePayload>
  fileSystemDelete?: Maybe<FileSystemDeletePayload>
  generateKubeconfigForCluster?: Maybe<GenerateKubeconfigForClusterPayload>
  hardware?: Maybe<HardwarePayload>
  hardwareDelete?: Maybe<HardwareDeletePayload>
  ingressRouteAssign?: Maybe<IngressRouteAssignPayload>
  ingressRouteCreate?: Maybe<IngressRouteCreatePayload>
  ingressRouteDelete?: Maybe<IngressRouteDeletePayload>
  login?: Maybe<LoginPayload>
  logout?: Maybe<LogoutPayload>
  passwordChange?: Maybe<PasswordChangePayload>
  releaseReservation?: Maybe<ReleaseReservationPayload>
  serverCreate?: Maybe<ServerCreatePayload>
  serverDelete?: Maybe<ServerDeletePayload>
  serverHardReboot?: Maybe<ServerHardRebootPayload>
  serverShutoff?: Maybe<ServerShutoffPayload>
  serverSoftReboot?: Maybe<ServerSoftRebootPayload>
  serverStart?: Maybe<ServerStartPayload>
  signup?: Maybe<SignupPayload>
  sshKeyDelete?: Maybe<SshKeyDeletePayload>
  sshKeyUpdateCreate?: Maybe<SshKeyUpdateCreatePayload>
  teamAddUser?: Maybe<TeamAddUserPayload>
  teamChangeUserGroup?: Maybe<TeamChangeUserGroupPayload>
  teamDelete?: Maybe<TeamDeletePayload>
  teamRemoveUser?: Maybe<TeamRemoveUserPayload>
  teamUpdateCreate?: Maybe<TeamUpdateCreatePayload>
  userDelete?: Maybe<UserDeletePayload>
  userUpdate?: Maybe<UserUpdatePayload>
  workflowJobCreate?: Maybe<WorkflowJobCreatePayload>
  workloadDefinitionCreateUpdate?: Maybe<WorkloadDefinitionUpdateCreatePayload>
  workloadDefinitionDelete?: Maybe<WorkloadDefinitionDeletePayload>
  workloadDefinitionUnarchive?: Maybe<WorkloadDefinitionUnarchivePayload>
  workloadRunCreateUpdate?: Maybe<WorkloadRunUpdateCreatePayload>
}

export type MutationClusterArgs = {
  input: ClusterInput
}

export type MutationClusterDeleteArgs = {
  input: ClusterDeleteInput
}

export type MutationCreateReservationArgs = {
  input: CreateReservationInput
}

export type MutationDeleteTokenArgs = {
  input: DeleteTokenInput
}

export type MutationFileSystemCreateArgs = {
  input: FileSystemCreateInput
}

export type MutationFileSystemDeleteArgs = {
  input: FileSystemDeleteInput
}

export type MutationGenerateKubeconfigForClusterArgs = {
  input: GenerateKubeconfigForClusterInput
}

export type MutationHardwareArgs = {
  input: HardwareInput
}

export type MutationHardwareDeleteArgs = {
  input: HardwareDeleteInput
}

export type MutationIngressRouteAssignArgs = {
  input: IngressRouteAssignInput
}

export type MutationIngressRouteCreateArgs = {
  input: IngressRouteCreateInput
}

export type MutationIngressRouteDeleteArgs = {
  input: IngressRouteDeleteInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationLogoutArgs = {
  input: LogoutInput
}

export type MutationPasswordChangeArgs = {
  input: PasswordChangeInput
}

export type MutationReleaseReservationArgs = {
  input: ReleaseReservationInput
}

export type MutationServerCreateArgs = {
  input: ServerCreateInput
}

export type MutationServerDeleteArgs = {
  input: ServerDeleteInput
}

export type MutationServerHardRebootArgs = {
  input: ServerHardRebootInput
}

export type MutationServerShutoffArgs = {
  input: ServerShutoffInput
}

export type MutationServerSoftRebootArgs = {
  input: ServerSoftRebootInput
}

export type MutationServerStartArgs = {
  input: ServerStartInput
}

export type MutationSignupArgs = {
  input: SignupInput
}

export type MutationSshKeyDeleteArgs = {
  input: SshKeyDeleteInput
}

export type MutationSshKeyUpdateCreateArgs = {
  input: SshKeyUpdateCreateInput
}

export type MutationTeamAddUserArgs = {
  input: TeamAddUserInput
}

export type MutationTeamChangeUserGroupArgs = {
  input: TeamChangeUserGroupInput
}

export type MutationTeamDeleteArgs = {
  input: TeamDeleteInput
}

export type MutationTeamRemoveUserArgs = {
  input: TeamRemoveUserInput
}

export type MutationTeamUpdateCreateArgs = {
  input: TeamUpdateCreateInput
}

export type MutationUserDeleteArgs = {
  input: UserDeleteInput
}

export type MutationUserUpdateArgs = {
  input: UserUpdateInput
}

export type MutationWorkflowJobCreateArgs = {
  input: WorkflowJobCreateInput
}

export type MutationWorkloadDefinitionCreateUpdateArgs = {
  input: WorkloadDefinitionUpdateCreateInput
}

export type MutationWorkloadDefinitionDeleteArgs = {
  input: WorkloadDefinitionDeleteInput
}

export type MutationWorkloadDefinitionUnarchiveArgs = {
  input: WorkloadDefinitionUnarchiveInput
}

export type MutationWorkloadRunCreateUpdateArgs = {
  input: WorkloadRunUpdateCreateInput
}

export type NetworkInterfaceConnection = {
  __typename?: 'NetworkInterfaceConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NetworkInterfaceEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `NetworkInterface` and its cursor. */
export type NetworkInterfaceEdge = {
  __typename?: 'NetworkInterfaceEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<NetworkInterfaceType>
}

export type NetworkInterfaceType = Node & {
  __typename?: 'NetworkInterfaceType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output']
}

/** An enumeration. */
export enum OpenStackFlavorAvailability {
  /** AVAILABLE */
  Available = 'AVAILABLE',
  /** DELETED */
  Deleted = 'DELETED',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** PREVIEW */
  Preview = 'PREVIEW',
}

export type OpenStackFlavorType = Node & {
  __typename?: 'OpenStackFlavorType'
  availability?: Maybe<OpenStackFlavorAvailability>
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Flavor name shown to user */
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OpenStackFlavorTypeConnection = {
  __typename?: 'OpenStackFlavorTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OpenStackFlavorTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `OpenStackFlavorType` and its cursor. */
export type OpenStackFlavorTypeEdge = {
  __typename?: 'OpenStackFlavorTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<OpenStackFlavorType>
}

/** An enumeration. */
export enum OpenStackImageAvailability {
  /** AVAILABLE */
  Available = 'AVAILABLE',
  /** DELETED */
  Deleted = 'DELETED',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** PREVIEW */
  Preview = 'PREVIEW',
}

export type OpenStackImageType = Node & {
  __typename?: 'OpenStackImageType'
  availability?: Maybe<OpenStackImageAvailability>
  createdAt: Scalars['DateTime']['output']
  defaultSshUser: Scalars['String']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type OpenStackImageTypeConnection = {
  __typename?: 'OpenStackImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OpenStackImageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `OpenStackImageType` and its cursor. */
export type OpenStackImageTypeEdge = {
  __typename?: 'OpenStackImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<OpenStackImageType>
}

/** An enumeration. */
export enum OpenStackServerStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** BUILD */
  Build = 'BUILD',
  /** CREATING */
  Creating = 'CREATING',
  /** DELETED */
  Deleted = 'DELETED',
  /** ERROR */
  Error = 'ERROR',
  /** HARD_REBOOT */
  HardReboot = 'HARD_REBOOT',
  /** INITIAL */
  Initial = 'INITIAL',
  /** INTERNAL_ERROR */
  InternalError = 'INTERNAL_ERROR',
  /** PAUSED */
  Paused = 'PAUSED',
  /** REBOOT */
  Reboot = 'REBOOT',
  /** REQUEST_CREATE */
  RequestCreate = 'REQUEST_CREATE',
  /** REQUEST_DELETE */
  RequestDelete = 'REQUEST_DELETE',
  /** REQUEST_HARD_REBOOT */
  RequestHardReboot = 'REQUEST_HARD_REBOOT',
  /** REQUEST_SHUTOFF */
  RequestShutoff = 'REQUEST_SHUTOFF',
  /** REQUEST_SOFT_REBOOT */
  RequestSoftReboot = 'REQUEST_SOFT_REBOOT',
  /** REQUEST_START */
  RequestStart = 'REQUEST_START',
  /** SHUTOFF */
  Shutoff = 'SHUTOFF',
  /** SUSPENDED */
  Suspended = 'SUSPENDED',
}

export type OpenStackServerType = Node & {
  __typename?: 'OpenStackServerType'
  /** Compute Host system of this OpenStack server instance */
  computeHost?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<UserType>
  errorMessage?: Maybe<Scalars['String']['output']>
  /** The ID of the object */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  openstackFlavor: OpenStackFlavorType
  openstackFloatingIp?: Maybe<Scalars['String']['output']>
  openstackImage: OpenStackImageType
  pk?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  status: OpenStackServerStatus
  updatedAt: Scalars['DateTime']['output']
}

export type OpenStackServerTypeConnection = {
  __typename?: 'OpenStackServerTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OpenStackServerTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `OpenStackServerType` and its cursor. */
export type OpenStackServerTypeEdge = {
  __typename?: 'OpenStackServerTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<OpenStackServerType>
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type PasswordChangeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type PasswordChangePayload = {
  __typename?: 'PasswordChangePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok?: Maybe<Scalars['Boolean']['output']>
}

export type Query = {
  __typename?: 'Query'
  cluster?: Maybe<ClusterType>
  clusters?: Maybe<ClusterTypeConnection>
  containerHardwareRequirements?: Maybe<ContainerHardwareRequirementsTypeConnection>
  containerRuntimeRequirements?: Maybe<ContainerRuntimeRequirementsTypeConnection>
  fileSystem?: Maybe<FileSystemType>
  fileSystems?: Maybe<FileSystemTypeConnection>
  flavor?: Maybe<OpenStackFlavorType>
  flavors?: Maybe<OpenStackFlavorTypeConnection>
  hardware?: Maybe<HardwareType>
  hardwareModels?: Maybe<HardwareModelTypeConnection>
  hardwareTags?: Maybe<HardwareTagTypeConnection>
  hardwares?: Maybe<HardwareTypeConnection>
  ingressRoutes?: Maybe<IngressRouteTypeConnection>
  kubeconfig: KubeconfigType
  openStackImage?: Maybe<OpenStackImageType>
  openStackImages?: Maybe<OpenStackImageTypeConnection>
  reservations?: Maybe<ReservationTypeConnection>
  server?: Maybe<OpenStackServerType>
  servers?: Maybe<OpenStackServerTypeConnection>
  sshKey?: Maybe<SshKeyType>
  sshKeys?: Maybe<SshKeyTypeConnection>
  team?: Maybe<TeamType>
  teams?: Maybe<TeamTypeConnection>
  token?: Maybe<TokenType>
  tokens?: Maybe<TokenType>
  user?: Maybe<UserType>
  users?: Maybe<UserTypeConnection>
  whoami?: Maybe<UserType>
  workloadDefinition?: Maybe<WorkloadDefinitionType>
  workloadDefinitions?: Maybe<WorkloadDefinitionTypeConnection>
  workloadRun?: Maybe<WorkloadRunType>
  workloadRuns?: Maybe<WorkloadRunTypeConnection>
  workloadRuntimeCapacity: WorkloadRuntimeCapacityType
}

export type QueryClusterArgs = {
  id: Scalars['ID']['input']
}

export type QueryClustersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  clusterSlug?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryContainerHardwareRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryContainerRuntimeRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryFileSystemArgs = {
  id: Scalars['ID']['input']
}

export type QueryFileSystemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_In?: InputMaybe<Array<InputMaybe<FileSystemStatus>>>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryFlavorArgs = {
  id: Scalars['ID']['input']
}

export type QueryFlavorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
}

export type QueryHardwareArgs = {
  id: Scalars['ID']['input']
}

export type QueryHardwareModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryHardwareTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryHardwaresArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  hardwareModel_ModelName_Icontains?: InputMaybe<Scalars['String']['input']>
  hardwareTagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isHealthy?: InputMaybe<Scalars['Boolean']['input']>
  isQuarantined?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  slug_Icontains?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Name_Icontains?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryIngressRoutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  workloadDefinitionId: Scalars['ID']['input']
}

export type QueryOpenStackImageArgs = {
  id: Scalars['ID']['input']
}

export type QueryOpenStackImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
}

export type QueryReservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  endedAt?: InputMaybe<Scalars['DateTime']['input']>
  endedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  endedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  endedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  requestedHardwareModel_ModelIdentifier_Icontains?: InputMaybe<
    Scalars['String']['input']
  >
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
  startedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  startedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  startedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  team_Name_Icontains?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Contains?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Gt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt_Lt?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryServerArgs = {
  id: Scalars['ID']['input']
}

export type QueryServersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  computeHost_Icontains?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  openstackFloatingIp_Icontains?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_In?: InputMaybe<Array<InputMaybe<OpenStackServerStatus>>>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QuerySshKeyArgs = {
  id: Scalars['ID']['input']
}

export type QuerySshKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTeamArgs = {
  id: Scalars['ID']['input']
}

export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTokensArgs = {
  id: Scalars['ID']['input']
}

export type QueryUserArgs = {
  id: Scalars['ID']['input']
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  email_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  username_Icontains?: InputMaybe<Scalars['String']['input']>
}

export type QueryWorkloadDefinitionArgs = {
  id: Scalars['ID']['input']
}

export type QueryWorkloadDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryWorkloadRunArgs = {
  id: Scalars['ID']['input']
}

export type QueryWorkloadRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdBy_FirstName_Icontains?: InputMaybe<Scalars['String']['input']>
  createdBy_LastName_Icontains?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lifecycleStatus?: InputMaybe<Scalars['String']['input']>
  lifecycleStatus_In?: InputMaybe<Array<InputMaybe<WorkloadRunLifecycleStatus>>>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  workloadDefinitionId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryWorkloadRuntimeCapacityArgs = {
  containerRuntimeId: Scalars['ID']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type ReleaseReservationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  reservationId: Scalars['ID']['input']
}

export type ReleaseReservationPayload = {
  __typename?: 'ReleaseReservationPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  reservation?: Maybe<ReservationType>
}

export type ReservationConnection = {
  __typename?: 'ReservationConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReservationEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `Reservation` and its cursor. */
export type ReservationEdge = {
  __typename?: 'ReservationEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ReservationType>
}

/** An enumeration. */
export enum ReservationStatus {
  /** canceled */
  Canceled = 'CANCELED',
  /** complete */
  Complete = 'COMPLETE',
  /** error */
  Error = 'ERROR',
  /** new */
  New = 'NEW',
  /** pending */
  Pending = 'PENDING',
  /** releasing */
  Releasing = 'RELEASING',
  /** reserved */
  Reserved = 'RESERVED',
  /** scheduled */
  Scheduled = 'SCHEDULED',
  /** searching */
  Searching = 'SEARCHING',
}

export type ReservationType = Node & {
  __typename?: 'ReservationType'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<UserType>
  endedAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
  requestedAmountOfHardwareModel: Scalars['Int']['output']
  requestedHardwareModel?: Maybe<HardwareModelType>
  reservedHardware: Array<HardwareType>
  startedAt: Scalars['DateTime']['output']
  status: ReservationStatus
  statusMessage?: Maybe<Scalars['String']['output']>
  team?: Maybe<TeamType>
  updatedAt: Scalars['DateTime']['output']
}

export type ReservationTypeConnection = {
  __typename?: 'ReservationTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReservationTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `ReservationType` and its cursor. */
export type ReservationTypeEdge = {
  __typename?: 'ReservationTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<ReservationType>
}

export type SshKeyDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type SshKeyDeletePayload = {
  __typename?: 'SSHKeyDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type SshKeyType = Node & {
  __typename?: 'SSHKeyType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  owner: UserType
  pk?: Maybe<Scalars['String']['output']>
}

export type SshKeyTypeConnection = {
  __typename?: 'SSHKeyTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SshKeyTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `SSHKeyType` and its cursor. */
export type SshKeyTypeEdge = {
  __typename?: 'SSHKeyTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<SshKeyType>
}

export type SshKeyUpdateCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  key: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type SshKeyUpdateCreatePayload = {
  __typename?: 'SSHKeyUpdateCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  sshKey?: Maybe<SshKeyType>
}

export type ServerConnection = {
  __typename?: 'ServerConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServerEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type ServerCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  fileSystems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  openstackFlavorId: Scalars['ID']['input']
  openstackImageId: Scalars['ID']['input']
  teamId: Scalars['ID']['input']
}

export type ServerCreatePayload = {
  __typename?: 'ServerCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server?: Maybe<OpenStackServerType>
}

export type ServerDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerDeletePayload = {
  __typename?: 'ServerDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

/** A Relay edge containing a `Server` and its cursor. */
export type ServerEdge = {
  __typename?: 'ServerEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<OpenStackServerType>
}

export type ServerHardRebootInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerHardRebootPayload = {
  __typename?: 'ServerHardRebootPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server?: Maybe<OpenStackServerType>
}

export type ServerShutoffInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerShutoffPayload = {
  __typename?: 'ServerShutoffPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server?: Maybe<OpenStackServerType>
}

export type ServerSoftRebootInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerSoftRebootPayload = {
  __typename?: 'ServerSoftRebootPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server?: Maybe<OpenStackServerType>
}

export type ServerStartInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type ServerStartPayload = {
  __typename?: 'ServerStartPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  server?: Maybe<OpenStackServerType>
}

export type SignupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
  signupCode: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type SignupPayload = {
  __typename?: 'SignupPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  token?: Maybe<TokenType>
}

export type TeamAddUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  teamId: Scalars['ID']['input']
  userId: Scalars['String']['input']
}

export type TeamAddUserPayload = {
  __typename?: 'TeamAddUserPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team: TeamType
}

export type TeamChangeUserGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  groupId: Scalars['ID']['input']
  teamId: Scalars['ID']['input']
  userId: Scalars['String']['input']
}

export type TeamChangeUserGroupPayload = {
  __typename?: 'TeamChangeUserGroupPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team?: Maybe<TeamType>
}

export type TeamDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type TeamDeletePayload = {
  __typename?: 'TeamDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type TeamRemoveUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  teamId: Scalars['ID']['input']
  userId: Scalars['String']['input']
}

export type TeamRemoveUserPayload = {
  __typename?: 'TeamRemoveUserPayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team: TeamType
}

export type TeamType = Node & {
  __typename?: 'TeamType'
  adminGroup?: Maybe<GroupType>
  clusterSet?: Maybe<ClusterConnection>
  containerhardwarerequirementsSet?: Maybe<ContainerHardwareRequirementsConnection>
  containerruntimerequirementsSet?: Maybe<ContainerRuntimeRequirementsConnection>
  createdAt: Scalars['DateTime']['output']
  filesystemSet?: Maybe<FileSystemConnection>
  group?: Maybe<GroupType>
  hardwareSet?: Maybe<HardwareConnection>
  hardwaremodelSet?: Maybe<HardwareModelConnection>
  hardwaretagSet?: Maybe<HardwareTagConnection>
  /** The ID of the object */
  id: Scalars['ID']['output']
  ingressrouteSet?: Maybe<IngressRouteConnection>
  isActive: Scalars['Boolean']['output']
  isHardwareEnabled: Scalars['Boolean']['output']
  isKubernetesEnabled: Scalars['Boolean']['output']
  isStorageEnabled: Scalars['Boolean']['output']
  isVmEnabled: Scalars['Boolean']['output']
  manufacturerSet?: Maybe<ManufacturerConnection>
  name: Scalars['String']['output']
  networkinterfaceSet?: Maybe<NetworkInterfaceConnection>
  openstackserverSet?: Maybe<ServerConnection>
  pk?: Maybe<Scalars['String']['output']>
  reservationSet?: Maybe<ReservationConnection>
  slug: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  workloaddefinitionSet?: Maybe<WorkloadDefinitionConnection>
  workloadrunSet?: Maybe<WorkloadRunConnection>
}

export type TeamTypeClusterSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeContainerhardwarerequirementsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeContainerruntimerequirementsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeFilesystemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeHardwareSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeHardwaremodelSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeHardwaretagSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeIngressrouteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeManufacturerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeNetworkinterfaceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeOpenstackserverSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeReservationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeWorkloaddefinitionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeWorkloadrunSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type TeamTypeConnection = {
  __typename?: 'TeamTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `TeamType` and its cursor. */
export type TeamTypeEdge = {
  __typename?: 'TeamTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<TeamType>
}

export type TeamUpdateCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
}

export type TeamUpdateCreatePayload = {
  __typename?: 'TeamUpdateCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  team?: Maybe<TeamType>
}

export type TokenType = Node & {
  __typename?: 'TokenType'
  createdAt: Scalars['DateTime']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  keyName: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  user: UserType
}

export type UserDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type UserDeletePayload = {
  __typename?: 'UserDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type UserType = Node & {
  __typename?: 'UserType'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output']
}

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<UserType>
}

export type UserUpdateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  id: Scalars['ID']['input']
  lastName: Scalars['String']['input']
}

export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  user?: Maybe<UserType>
}

export type WorkflowJobCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  clusterId?: InputMaybe<Scalars['String']['input']>
  hardwareId?: InputMaybe<Scalars['String']['input']>
}

export type WorkflowJobCreatePayload = {
  __typename?: 'WorkflowJobCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workflowJob?: Maybe<WorkflowJobType>
}

export type WorkflowJobType = Node & {
  __typename?: 'WorkflowJobType'
  /** The ID of the object */
  id: Scalars['ID']['output']
  pk?: Maybe<Scalars['String']['output']>
}

export type WorkloadDefinitionConnection = {
  __typename?: 'WorkloadDefinitionConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkloadDefinitionEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

export type WorkloadDefinitionDeleteInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type WorkloadDefinitionDeletePayload = {
  __typename?: 'WorkloadDefinitionDeletePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

/** A Relay edge containing a `WorkloadDefinition` and its cursor. */
export type WorkloadDefinitionEdge = {
  __typename?: 'WorkloadDefinitionEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<WorkloadDefinitionType>
}

export type WorkloadDefinitionType = Node & {
  __typename?: 'WorkloadDefinitionType'
  createdBy?: Maybe<UserType>
  environmentVariables: Scalars['JSONString']['output']
  fileSystems: FileSystemTypeConnection
  /** The ID of the object */
  id: Scalars['ID']['output']
  /** Workload Definition for which Runs the IngressRoute is intended */
  ingressrouteSet: IngressRouteTypeConnection
  livenessPath?: Maybe<Scalars['String']['output']>
  livenessPort?: Maybe<Scalars['Int']['output']>
  memoryLimit: Scalars['String']['output']
  metricsPath?: Maybe<Scalars['String']['output']>
  metricsPort?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  pk?: Maybe<Scalars['String']['output']>
  readinessPath?: Maybe<Scalars['String']['output']>
  readinessPort?: Maybe<Scalars['Int']['output']>
  registryIsConfigured: Scalars['Boolean']['output']
  slug: Scalars['String']['output']
}

export type WorkloadDefinitionTypeFileSystemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdBy_Username_Icontains?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  name_Icontains?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_In?: InputMaybe<Array<InputMaybe<FileSystemStatus>>>
}

export type WorkloadDefinitionTypeIngressrouteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type WorkloadDefinitionTypeConnection = {
  __typename?: 'WorkloadDefinitionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkloadDefinitionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `WorkloadDefinitionType` and its cursor. */
export type WorkloadDefinitionTypeEdge = {
  __typename?: 'WorkloadDefinitionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<WorkloadDefinitionType>
}

export type WorkloadDefinitionUnarchiveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type WorkloadDefinitionUnarchivePayload = {
  __typename?: 'WorkloadDefinitionUnarchivePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  ok: Scalars['Boolean']['output']
}

export type WorkloadDefinitionUpdateCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  environmentVariables?: InputMaybe<Scalars['JSONString']['input']>
  fileSystems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  imageRegistrySecrets?: InputMaybe<Scalars['JSONString']['input']>
  livenessPath?: InputMaybe<Scalars['String']['input']>
  livenessPort?: InputMaybe<Scalars['Int']['input']>
  memoryLimit?: InputMaybe<Scalars['String']['input']>
  metricsPath?: InputMaybe<Scalars['String']['input']>
  metricsPort?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  readinessPath?: InputMaybe<Scalars['String']['input']>
  readinessPort?: InputMaybe<Scalars['Int']['input']>
  teamId: Scalars['ID']['input']
}

export type WorkloadDefinitionUpdateCreatePayload = {
  __typename?: 'WorkloadDefinitionUpdateCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadDefinition?: Maybe<WorkloadDefinitionType>
}

export type WorkloadRunConnection = {
  __typename?: 'WorkloadRunConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkloadRunEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `WorkloadRun` and its cursor. */
export type WorkloadRunEdge = {
  __typename?: 'WorkloadRunEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<WorkloadRunType>
}

/** An enumeration. */
export enum WorkloadRunLifecycleStatus {
  /** complete */
  Complete = 'COMPLETE',
  /** deleted */
  Deleted = 'DELETED',
  /** error */
  Error = 'ERROR',
  /** failed */
  Failed = 'FAILED',
  /** fulfilling_reservations */
  FulfillingReservations = 'FULFILLING_RESERVATIONS',
  /** generating_yaml */
  GeneratingYaml = 'GENERATING_YAML',
  /** new */
  New = 'NEW',
  /** pending */
  Pending = 'PENDING',
  /** release_reservation */
  ReleaseReservations = 'RELEASE_RESERVATIONS',
  /** request_reservations */
  RequestReservations = 'REQUEST_RESERVATIONS',
  /** request_start */
  RequestStart = 'REQUEST_START',
  /** request_stop */
  RequestStop = 'REQUEST_STOP',
  /** reservation_failed */
  ReservationFailed = 'RESERVATION_FAILED',
  /** running */
  Running = 'RUNNING',
  /** scheduled */
  Scheduled = 'SCHEDULED',
  /** stopping */
  Stopping = 'STOPPING',
}

export type WorkloadRunType = Node & {
  __typename?: 'WorkloadRunType'
  /** This is user defined! */
  containerArgs?: Maybe<Scalars['String']['output']>
  /** This should be the container's shell, please use /bin/bash but could be /bin/sh */
  containerCommand?: Maybe<Scalars['String']['output']>
  containerFullImagePath: Scalars['String']['output']
  containerHardwareRequirements: Array<ContainerHardwareRequirementsType>
  containerPorts: Scalars['JSONString']['output']
  containerRuntimeRequirements?: Maybe<ContainerRuntimeRequirementsType>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<UserType>
  /** The ID of the object */
  id: Scalars['ID']['output']
  ingressRouteMatch?: Maybe<Scalars['String']['output']>
  /** Workload Run to direct the requests to. If unset, IngressRoute resource will be deleted. */
  ingressrouteSet: IngressRouteTypeConnection
  lifecycleStatus: WorkloadRunLifecycleStatus
  namespace?: Maybe<Scalars['String']['output']>
  pk?: Maybe<Scalars['String']['output']>
  /** Constrain deployment to team hardware only. */
  requireTeamHardware: Scalars['Boolean']['output']
  runNumber: Scalars['Int']['output']
  serviceName?: Maybe<Scalars['String']['output']>
  servicePort?: Maybe<Scalars['Int']['output']>
  statusMessage?: Maybe<Scalars['String']['output']>
  /** Enable sticky sessions for this ingressroute */
  stickySessions: Scalars['Boolean']['output']
  team?: Maybe<TeamType>
  updatedAt: Scalars['DateTime']['output']
  workloadNodeName?: Maybe<Scalars['String']['output']>
  /** Number of containers in Running state */
  workloadReplicas: Scalars['Int']['output']
  workloadRunScale: Scalars['Int']['output']
  workloadType: Scalars['String']['output']
}

export type WorkloadRunTypeIngressrouteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type WorkloadRunTypeConnection = {
  __typename?: 'WorkloadRunTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WorkloadRunTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Number of items in the queryset. */
  totalCount: Scalars['Int']['output']
}

/** A Relay edge containing a `WorkloadRunType` and its cursor. */
export type WorkloadRunTypeEdge = {
  __typename?: 'WorkloadRunTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node?: Maybe<WorkloadRunType>
}

export type WorkloadRunUpdateCreateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  containerArgs?: InputMaybe<Scalars['String']['input']>
  containerCommand?: InputMaybe<Scalars['String']['input']>
  containerFullImagePath?: InputMaybe<Scalars['String']['input']>
  containerHardwareRequirementIds?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >
  containerPorts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  containerRuntimeRequirementId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  lifecycleStatus?: InputMaybe<Scalars['String']['input']>
  requireTeamHardware?: InputMaybe<Scalars['Boolean']['input']>
  servicePort?: InputMaybe<Scalars['Int']['input']>
  stickySessions?: InputMaybe<Scalars['Boolean']['input']>
  teamId: Scalars['ID']['input']
  workloadDefinitionId?: InputMaybe<Scalars['ID']['input']>
  workloadRunScale?: InputMaybe<Scalars['Int']['input']>
}

export type WorkloadRunUpdateCreatePayload = {
  __typename?: 'WorkloadRunUpdateCreatePayload'
  clientMutationId?: Maybe<Scalars['String']['output']>
  workloadRun?: Maybe<WorkloadRunType>
}

export type WorkloadRuntimeCapacityType = {
  __typename?: 'WorkloadRuntimeCapacityType'
  maximumScale?: Maybe<Scalars['Int']['output']>
  singleInstanceCapacity?: Maybe<Scalars['Int']['output']>
}

export type LoginMutationVariables = Exact<{
  input: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'LoginPayload'
    token?: {
      __typename?: 'TokenType'
      id: string
      key: string
      keyName: string
      createdAt: any
      user: { __typename?: 'UserType'; username: string }
    } | null
  } | null
}

export type LogoutMutationVariables = Exact<{
  input: LogoutInput
}>

export type LogoutMutation = {
  __typename?: 'Mutation'
  logout?: { __typename?: 'LogoutPayload'; ok?: boolean | null } | null
}

export type SignupMutationVariables = Exact<{
  input: SignupInput
}>

export type SignupMutation = {
  __typename?: 'Mutation'
  signup?: {
    __typename?: 'SignupPayload'
    token?: {
      __typename?: 'TokenType'
      id: string
      key: string
      keyName: string
      createdAt: any
      user: { __typename?: 'UserType'; username: string }
    } | null
  } | null
}

export type PasswordChangeMutationVariables = Exact<{
  input: PasswordChangeInput
}>

export type PasswordChangeMutation = {
  __typename?: 'Mutation'
  passwordChange?: {
    __typename?: 'PasswordChangePayload'
    ok?: boolean | null
  } | null
}

export type ClusterFragmentFragment = {
  __typename?: 'ClusterType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  slug: string
  masterNodes: {
    __typename?: 'HardwareTypeConnection'
    edges: Array<{
      __typename?: 'HardwareTypeEdge'
      node?: {
        __typename?: 'HardwareType'
        id: string
        pk?: string | null
        slug: string
      } | null
    } | null>
  }
  workerNodes: {
    __typename?: 'HardwareTypeConnection'
    edges: Array<{
      __typename?: 'HardwareTypeEdge'
      node?: {
        __typename?: 'HardwareType'
        id: string
        pk?: string | null
        slug: string
      } | null
    } | null>
  }
}

export type ClustersQueryVariables = Exact<{
  teamId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
}>

export type ClustersQuery = {
  __typename?: 'Query'
  clusters?: {
    __typename?: 'ClusterTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'ClusterTypeEdge'
      node?: {
        __typename?: 'ClusterType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        slug: string
        masterNodes: {
          __typename?: 'HardwareTypeConnection'
          edges: Array<{
            __typename?: 'HardwareTypeEdge'
            node?: {
              __typename?: 'HardwareType'
              id: string
              pk?: string | null
              slug: string
            } | null
          } | null>
        }
        workerNodes: {
          __typename?: 'HardwareTypeConnection'
          edges: Array<{
            __typename?: 'HardwareTypeEdge'
            node?: {
              __typename?: 'HardwareType'
              id: string
              pk?: string | null
              slug: string
            } | null
          } | null>
        }
      } | null
    } | null>
  } | null
}

export type ContainerHardwareRequirementsFragmentFragment = {
  __typename?: 'ContainerHardwareRequirementsType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  amountOfHardwareModels: number
  hardwareModel: {
    __typename?: 'HardwareModelType'
    modelName: string
    modelIdentifier: string
    manufacturer?: { __typename?: 'ManufacturerType'; slug: string } | null
  }
}

export type ContainerHardwareRequirementsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type ContainerHardwareRequirementsQuery = {
  __typename?: 'Query'
  containerHardwareRequirements?: {
    __typename?: 'ContainerHardwareRequirementsTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'ContainerHardwareRequirementsTypeEdge'
      node?: {
        __typename?: 'ContainerHardwareRequirementsType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        amountOfHardwareModels: number
        hardwareModel: {
          __typename?: 'HardwareModelType'
          modelName: string
          modelIdentifier: string
          manufacturer?: {
            __typename?: 'ManufacturerType'
            slug: string
          } | null
        }
      } | null
    } | null>
  } | null
}

export type ContainerRuntimeRequirementsQueryVariables = Exact<{
  [key: string]: never
}>

export type ContainerRuntimeRequirementsQuery = {
  __typename?: 'Query'
  containerRuntimeRequirements?: {
    __typename?: 'ContainerRuntimeRequirementsTypeConnection'
    edges: Array<{
      __typename?: 'ContainerRuntimeRequirementsTypeEdge'
      node?: {
        __typename?: 'ContainerRuntimeRequirementsType'
        id: string
        label: string
        availability: ContainerRuntimeRequirementsAvailability
        resourceName: string
      } | null
    } | null>
  } | null
}

export type FileSystemFragmentFragment = {
  __typename?: 'FileSystemType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
  status: FileSystemStatus
  capacity: string
  errorMessage?: string | null
  createdBy?: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  } | null
  team?: { __typename?: 'TeamType'; name: string } | null
}

export type FileSystemQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type FileSystemQuery = {
  __typename?: 'Query'
  fileSystem?: {
    __typename?: 'FileSystemType'
    id: string
    pk?: string | null
    createdAt: any
    updatedAt: any
    name: string
    slug: string
    status: FileSystemStatus
    capacity: string
    errorMessage?: string | null
    createdBy?: {
      __typename?: 'UserType'
      id: string
      firstName: string
      lastName: string
      username: string
    } | null
    team?: { __typename?: 'TeamType'; name: string } | null
  } | null
}

export type FileSystemsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<FileSystemStatus> | FileSystemStatus>
  name?: InputMaybe<Scalars['String']['input']>
  createdBy?: InputMaybe<Scalars['String']['input']>
}>

export type FileSystemsQuery = {
  __typename?: 'Query'
  fileSystems?: {
    __typename?: 'FileSystemTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'FileSystemTypeEdge'
      node?: {
        __typename?: 'FileSystemType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
        status: FileSystemStatus
        capacity: string
        errorMessage?: string | null
        createdBy?: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        } | null
        team?: { __typename?: 'TeamType'; name: string } | null
      } | null
    } | null>
  } | null
}

export type FileSystemCreateMutationVariables = Exact<{
  input: FileSystemCreateInput
}>

export type FileSystemCreateMutation = {
  __typename?: 'Mutation'
  fileSystemCreate?: {
    __typename?: 'FileSystemCreatePayload'
    fileSystem?: {
      __typename?: 'FileSystemType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: FileSystemStatus
      capacity: string
      errorMessage?: string | null
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
      team?: { __typename?: 'TeamType'; name: string } | null
    } | null
  } | null
}

export type FileSystemDeleteMutationVariables = Exact<{
  input: FileSystemDeleteInput
}>

export type FileSystemDeleteMutation = {
  __typename?: 'Mutation'
  fileSystemDelete?: {
    __typename?: 'FileSystemDeletePayload'
    fileSystem?: {
      __typename?: 'FileSystemType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: FileSystemStatus
      capacity: string
      errorMessage?: string | null
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
      team?: { __typename?: 'TeamType'; name: string } | null
    } | null
  } | null
}

export type FlavorFragmentFragment = {
  __typename?: 'OpenStackFlavorType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
}

export type FlavorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
}>

export type FlavorsQuery = {
  __typename?: 'Query'
  flavors?: {
    __typename?: 'OpenStackFlavorTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'OpenStackFlavorTypeEdge'
      node?: {
        __typename?: 'OpenStackFlavorType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
      } | null
    } | null>
  } | null
}

export type HardwareModelFragmentFragment = {
  __typename?: 'HardwareModelType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  modelName: string
  modelIdentifier: string
  manufacturer?: { __typename?: 'ManufacturerType'; slug: string } | null
}

export type HardwareModelsQueryVariables = Exact<{
  teamId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type HardwareModelsQuery = {
  __typename?: 'Query'
  hardwareModels?: {
    __typename?: 'HardwareModelTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'HardwareModelTypeEdge'
      node?: {
        __typename?: 'HardwareModelType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        modelName: string
        modelIdentifier: string
        manufacturer?: { __typename?: 'ManufacturerType'; slug: string } | null
      } | null
    } | null>
  } | null
}

export type HardwareTagFragmentFragment = {
  __typename?: 'HardwareTagType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  slug: string
}

export type HardwareTagsQueryVariables = Exact<{
  teamId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type HardwareTagsQuery = {
  __typename?: 'Query'
  hardwareTags?: {
    __typename?: 'HardwareTagTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'HardwareTagTypeEdge'
      node?: {
        __typename?: 'HardwareTagType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        slug: string
      } | null
    } | null>
  } | null
}

export type HardwareFragmentFragment = {
  __typename?: 'HardwareType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  teamName?: string | null
  slug: string
  macAddress?: string | null
  serialNumber: string
  dnsRecord: string
  isHealthy: boolean
  isProvisioning: boolean
  isQuarantined: boolean
  canBeReserved: boolean
  isReserved: boolean
  availableForReservation?: boolean | null
  hardwareModel?: {
    __typename?: 'HardwareModelType'
    modelName: string
    modelIdentifier: string
  } | null
}

export type HardwaresQueryVariables = Exact<{
  teamId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  isHealthy?: InputMaybe<Scalars['Boolean']['input']>
  isQuarantined?: InputMaybe<Scalars['Boolean']['input']>
  hardwareTagIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >
  teamName?: InputMaybe<Scalars['String']['input']>
  hardwareModelName?: InputMaybe<Scalars['String']['input']>
}>

export type HardwaresQuery = {
  __typename?: 'Query'
  hardwares?: {
    __typename?: 'HardwareTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'HardwareTypeEdge'
      node?: {
        __typename?: 'HardwareType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        teamName?: string | null
        slug: string
        macAddress?: string | null
        serialNumber: string
        dnsRecord: string
        isHealthy: boolean
        isProvisioning: boolean
        isQuarantined: boolean
        canBeReserved: boolean
        isReserved: boolean
        availableForReservation?: boolean | null
        hardwareModel?: {
          __typename?: 'HardwareModelType'
          modelName: string
          modelIdentifier: string
        } | null
      } | null
    } | null>
  } | null
}

export type IngressRouteFragmentFragment = {
  __typename?: 'IngressRouteType'
  id: string
  url: string
  workloadRun?: {
    __typename?: 'WorkloadRunType'
    id: string
    namespace?: string | null
  } | null
}

export type IngressRoutesQueryVariables = Exact<{
  workloadDefinitionId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type IngressRoutesQuery = {
  __typename?: 'Query'
  ingressRoutes?: {
    __typename?: 'IngressRouteTypeConnection'
    edges: Array<{
      __typename?: 'IngressRouteTypeEdge'
      node?: {
        __typename?: 'IngressRouteType'
        id: string
        url: string
        workloadRun?: {
          __typename?: 'WorkloadRunType'
          id: string
          namespace?: string | null
        } | null
      } | null
    } | null>
  } | null
}

export type IngressRouteCreateMutationVariables = Exact<{
  input: IngressRouteCreateInput
}>

export type IngressRouteCreateMutation = {
  __typename?: 'Mutation'
  ingressRouteCreate?: {
    __typename?: 'IngressRouteCreatePayload'
    ok: boolean
  } | null
}

export type IngressRouteDeleteMutationVariables = Exact<{
  input: IngressRouteDeleteInput
}>

export type IngressRouteDeleteMutation = {
  __typename?: 'Mutation'
  ingressRouteDelete?: {
    __typename?: 'IngressRouteDeletePayload'
    ok: boolean
  } | null
}

export type IngressRouteAssignMutationVariables = Exact<{
  input: IngressRouteAssignInput
}>

export type IngressRouteAssignMutation = {
  __typename?: 'Mutation'
  ingressRouteAssign?: {
    __typename?: 'IngressRouteAssignPayload'
    ok: boolean
  } | null
}

export type OpenStackImageFragmentFragment = {
  __typename?: 'OpenStackImageType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
}

export type OpenStackImagesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  availability?: InputMaybe<Scalars['String']['input']>
}>

export type OpenStackImagesQuery = {
  __typename?: 'Query'
  openStackImages?: {
    __typename?: 'OpenStackImageTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'OpenStackImageTypeEdge'
      node?: {
        __typename?: 'OpenStackImageType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
      } | null
    } | null>
  } | null
}

export type ReservationFragmentFragment = {
  __typename?: 'ReservationType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  requestedAmountOfHardwareModel: number
  status: ReservationStatus
  statusMessage?: string | null
  startedAt: any
  endedAt: any
  createdBy?: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  } | null
  team?: { __typename?: 'TeamType'; name: string } | null
  requestedHardwareModel?: {
    __typename?: 'HardwareModelType'
    modelName: string
    modelIdentifier: string
  } | null
  reservedHardware: Array<{
    __typename?: 'HardwareType'
    id: string
    pk?: string | null
    slug: string
  }>
}

export type ReservationsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  createdBy?: InputMaybe<Scalars['String']['input']>
  team?: InputMaybe<Scalars['String']['input']>
  hardwareModel?: InputMaybe<Scalars['String']['input']>
}>

export type ReservationsQuery = {
  __typename?: 'Query'
  reservations?: {
    __typename?: 'ReservationTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'ReservationTypeEdge'
      node?: {
        __typename?: 'ReservationType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        requestedAmountOfHardwareModel: number
        status: ReservationStatus
        statusMessage?: string | null
        startedAt: any
        endedAt: any
        createdBy?: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        } | null
        team?: { __typename?: 'TeamType'; name: string } | null
        requestedHardwareModel?: {
          __typename?: 'HardwareModelType'
          modelName: string
          modelIdentifier: string
        } | null
        reservedHardware: Array<{
          __typename?: 'HardwareType'
          id: string
          pk?: string | null
          slug: string
        }>
      } | null
    } | null>
  } | null
}

export type CreateReservationMutationVariables = Exact<{
  input: CreateReservationInput
}>

export type CreateReservationMutation = {
  __typename?: 'Mutation'
  createReservation?: {
    __typename?: 'CreateReservationPayload'
    reservation?: {
      __typename?: 'ReservationType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      requestedAmountOfHardwareModel: number
      status: ReservationStatus
      statusMessage?: string | null
      startedAt: any
      endedAt: any
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
      team?: { __typename?: 'TeamType'; name: string } | null
      requestedHardwareModel?: {
        __typename?: 'HardwareModelType'
        modelName: string
        modelIdentifier: string
      } | null
      reservedHardware: Array<{
        __typename?: 'HardwareType'
        id: string
        pk?: string | null
        slug: string
      }>
    } | null
  } | null
}

export type ReleaseReservationMutationVariables = Exact<{
  input: ReleaseReservationInput
}>

export type ReleaseReservationMutation = {
  __typename?: 'Mutation'
  releaseReservation?: {
    __typename?: 'ReleaseReservationPayload'
    reservation?: {
      __typename?: 'ReservationType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      requestedAmountOfHardwareModel: number
      status: ReservationStatus
      statusMessage?: string | null
      startedAt: any
      endedAt: any
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
      team?: { __typename?: 'TeamType'; name: string } | null
      requestedHardwareModel?: {
        __typename?: 'HardwareModelType'
        modelName: string
        modelIdentifier: string
      } | null
      reservedHardware: Array<{
        __typename?: 'HardwareType'
        id: string
        pk?: string | null
        slug: string
      }>
    } | null
  } | null
}

export type ServerFragmentFragment = {
  __typename?: 'OpenStackServerType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
  status: OpenStackServerStatus
  openstackFloatingIp?: string | null
  computeHost?: string | null
  errorMessage?: string | null
  openstackImage: {
    __typename?: 'OpenStackImageType'
    name: string
    defaultSshUser: string
  }
  openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
  createdBy?: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  } | null
}

export type ServerQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ServerQuery = {
  __typename?: 'Query'
  server?: {
    __typename?: 'OpenStackServerType'
    id: string
    pk?: string | null
    createdAt: any
    updatedAt: any
    name: string
    slug: string
    status: OpenStackServerStatus
    openstackFloatingIp?: string | null
    computeHost?: string | null
    errorMessage?: string | null
    openstackImage: {
      __typename?: 'OpenStackImageType'
      name: string
      defaultSshUser: string
    }
    openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
    createdBy?: {
      __typename?: 'UserType'
      id: string
      firstName: string
      lastName: string
      username: string
    } | null
  } | null
}

export type ServersQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<OpenStackServerStatus> | OpenStackServerStatus>
  createdBy?: InputMaybe<Scalars['String']['input']>
  computeHost?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ip?: InputMaybe<Scalars['String']['input']>
}>

export type ServersQuery = {
  __typename?: 'Query'
  servers?: {
    __typename?: 'OpenStackServerTypeConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'OpenStackServerTypeEdge'
      node?: {
        __typename?: 'OpenStackServerType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
        status: OpenStackServerStatus
        openstackFloatingIp?: string | null
        computeHost?: string | null
        errorMessage?: string | null
        openstackImage: {
          __typename?: 'OpenStackImageType'
          name: string
          defaultSshUser: string
        }
        openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
        createdBy?: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        } | null
      } | null
    } | null>
  } | null
}

export type ServerCreateMutationVariables = Exact<{
  input: ServerCreateInput
}>

export type ServerCreateMutation = {
  __typename?: 'Mutation'
  serverCreate?: {
    __typename?: 'ServerCreatePayload'
    server?: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
    } | null
  } | null
}

export type ServerDeleteMutationVariables = Exact<{
  input: ServerDeleteInput
}>

export type ServerDeleteMutation = {
  __typename?: 'Mutation'
  serverDelete?: { __typename?: 'ServerDeletePayload'; ok: boolean } | null
}

export type ServerStartMutationVariables = Exact<{
  input: ServerStartInput
}>

export type ServerStartMutation = {
  __typename?: 'Mutation'
  serverStart?: {
    __typename?: 'ServerStartPayload'
    server?: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
    } | null
  } | null
}

export type ServerShutoffMutationVariables = Exact<{
  input: ServerShutoffInput
}>

export type ServerShutoffMutation = {
  __typename?: 'Mutation'
  serverShutoff?: {
    __typename?: 'ServerShutoffPayload'
    server?: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
    } | null
  } | null
}

export type ServerSoftRebootMutationVariables = Exact<{
  input: ServerSoftRebootInput
}>

export type ServerSoftRebootMutation = {
  __typename?: 'Mutation'
  serverSoftReboot?: {
    __typename?: 'ServerSoftRebootPayload'
    server?: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
    } | null
  } | null
}

export type ServerHardRebootMutationVariables = Exact<{
  input: ServerHardRebootInput
}>

export type ServerHardRebootMutation = {
  __typename?: 'Mutation'
  serverHardReboot?: {
    __typename?: 'ServerHardRebootPayload'
    server?: {
      __typename?: 'OpenStackServerType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      status: OpenStackServerStatus
      openstackFloatingIp?: string | null
      computeHost?: string | null
      errorMessage?: string | null
      openstackImage: {
        __typename?: 'OpenStackImageType'
        name: string
        defaultSshUser: string
      }
      openstackFlavor: { __typename?: 'OpenStackFlavorType'; name: string }
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
    } | null
  } | null
}

export type SshKeyFragmentFragment = {
  __typename?: 'SSHKeyType'
  id: string
  pk?: string | null
  createdAt: any
  name: string
  key: string
  owner: { __typename?: 'UserType'; id: string }
}

export type SshKeyQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SshKeyQuery = {
  __typename?: 'Query'
  sshKey?: {
    __typename?: 'SSHKeyType'
    id: string
    pk?: string | null
    createdAt: any
    name: string
    key: string
    owner: { __typename?: 'UserType'; id: string }
  } | null
}

export type SshKeysQueryVariables = Exact<{ [key: string]: never }>

export type SshKeysQuery = {
  __typename?: 'Query'
  sshKeys?: {
    __typename?: 'SSHKeyTypeConnection'
    edges: Array<{
      __typename?: 'SSHKeyTypeEdge'
      node?: {
        __typename?: 'SSHKeyType'
        id: string
        pk?: string | null
        createdAt: any
        name: string
        key: string
        owner: { __typename?: 'UserType'; id: string }
      } | null
    } | null>
  } | null
}

export type SshKeyUpdateCreateMutationVariables = Exact<{
  input: SshKeyUpdateCreateInput
}>

export type SshKeyUpdateCreateMutation = {
  __typename?: 'Mutation'
  sshKeyUpdateCreate?: {
    __typename?: 'SSHKeyUpdateCreatePayload'
    sshKey?: {
      __typename?: 'SSHKeyType'
      id: string
      pk?: string | null
      createdAt: any
      name: string
      key: string
      owner: { __typename?: 'UserType'; id: string }
    } | null
  } | null
}

export type SshKeyDeleteMutationVariables = Exact<{
  input: SshKeyDeleteInput
}>

export type SshKeyDeleteMutation = {
  __typename?: 'Mutation'
  sshKeyDelete?: { __typename?: 'SSHKeyDeletePayload'; ok: boolean } | null
}

export type TeamFragmentFragment = {
  __typename?: 'TeamType'
  id: string
  pk?: string | null
  createdAt: any
  updatedAt: any
  name: string
  slug: string
  isActive: boolean
  isKubernetesEnabled: boolean
  isHardwareEnabled: boolean
  isStorageEnabled: boolean
  isVmEnabled: boolean
}

export type TeamQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type TeamQuery = {
  __typename?: 'Query'
  team?: {
    __typename?: 'TeamType'
    id: string
    pk?: string | null
    createdAt: any
    updatedAt: any
    name: string
    slug: string
    isActive: boolean
    isKubernetesEnabled: boolean
    isHardwareEnabled: boolean
    isStorageEnabled: boolean
    isVmEnabled: boolean
    adminGroup?: {
      __typename?: 'GroupType'
      id: string
      name: string
      userSet: {
        __typename?: 'UserTypeConnection'
        edges: Array<{
          __typename?: 'UserTypeEdge'
          node?: {
            __typename?: 'UserType'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
        } | null>
      }
    } | null
    group?: {
      __typename?: 'GroupType'
      id: string
      name: string
      userSet: {
        __typename?: 'UserTypeConnection'
        edges: Array<{
          __typename?: 'UserTypeEdge'
          node?: {
            __typename?: 'UserType'
            id: string
            username: string
            firstName: string
            lastName: string
          } | null
        } | null>
      }
    } | null
  } | null
}

export type TeamsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>
}>

export type TeamsQuery = {
  __typename?: 'Query'
  teams?: {
    __typename?: 'TeamTypeConnection'
    edges: Array<{
      __typename?: 'TeamTypeEdge'
      node?: {
        __typename?: 'TeamType'
        id: string
        pk?: string | null
        createdAt: any
        updatedAt: any
        name: string
        slug: string
        isActive: boolean
        isKubernetesEnabled: boolean
        isHardwareEnabled: boolean
        isStorageEnabled: boolean
        isVmEnabled: boolean
      } | null
    } | null>
  } | null
}

export type TeamUpdateCreateMutationVariables = Exact<{
  input: TeamUpdateCreateInput
}>

export type TeamUpdateCreateMutation = {
  __typename?: 'Mutation'
  teamUpdateCreate?: {
    __typename?: 'TeamUpdateCreatePayload'
    team?: {
      __typename?: 'TeamType'
      id: string
      pk?: string | null
      createdAt: any
      updatedAt: any
      name: string
      slug: string
      isActive: boolean
      isKubernetesEnabled: boolean
      isHardwareEnabled: boolean
      isStorageEnabled: boolean
      isVmEnabled: boolean
    } | null
  } | null
}

export type TeamDeleteMutationVariables = Exact<{
  input: TeamDeleteInput
}>

export type TeamDeleteMutation = {
  __typename?: 'Mutation'
  teamDelete?: { __typename?: 'TeamDeletePayload'; ok: boolean } | null
}

export type TeamChangeUserGroupMutationVariables = Exact<{
  input: TeamChangeUserGroupInput
}>

export type TeamChangeUserGroupMutation = {
  __typename?: 'Mutation'
  teamChangeUserGroup?: {
    __typename?: 'TeamChangeUserGroupPayload'
    team?: { __typename?: 'TeamType'; id: string } | null
  } | null
}

export type TeamAddUserMutationVariables = Exact<{
  input: TeamAddUserInput
}>

export type TeamAddUserMutation = {
  __typename?: 'Mutation'
  teamAddUser?: {
    __typename?: 'TeamAddUserPayload'
    team: { __typename?: 'TeamType'; id: string }
  } | null
}

export type TeamRemoveUserMutationVariables = Exact<{
  input: TeamRemoveUserInput
}>

export type TeamRemoveUserMutation = {
  __typename?: 'Mutation'
  teamRemoveUser?: {
    __typename?: 'TeamRemoveUserPayload'
    team: { __typename?: 'TeamType'; id: string }
  } | null
}

export type BasicUserFragmentFragment = {
  __typename?: 'UserType'
  id: string
  pk?: string | null
  username: string
  email: string
  firstName: string
  lastName: string
  isSuperuser: boolean
}

export type UsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
}>

export type UsersQuery = {
  __typename?: 'Query'
  users?: {
    __typename?: 'UserTypeConnection'
    edges: Array<{
      __typename?: 'UserTypeEdge'
      node?: {
        __typename?: 'UserType'
        id: string
        pk?: string | null
        username: string
        email: string
        firstName: string
        lastName: string
        isSuperuser: boolean
      } | null
    } | null>
  } | null
}

export type WhoamiQueryVariables = Exact<{ [key: string]: never }>

export type WhoamiQuery = {
  __typename?: 'Query'
  whoami?: {
    __typename?: 'UserType'
    id: string
    pk?: string | null
    username: string
    email: string
    firstName: string
    lastName: string
    isSuperuser: boolean
  } | null
}

export type UserUpdateMutationVariables = Exact<{
  input: UserUpdateInput
}>

export type UserUpdateMutation = {
  __typename?: 'Mutation'
  userUpdate?: {
    __typename?: 'UserUpdatePayload'
    user?: {
      __typename?: 'UserType'
      id: string
      pk?: string | null
      username: string
      email: string
      firstName: string
      lastName: string
      isSuperuser: boolean
    } | null
  } | null
}

export type WorkflowJobFragmentFragment = {
  __typename?: 'WorkflowJobType'
  id: string
}

export type WorkflowJobCreateMutationVariables = Exact<{
  input: WorkflowJobCreateInput
}>

export type WorkflowJobCreateMutation = {
  __typename?: 'Mutation'
  workflowJobCreate?: {
    __typename?: 'WorkflowJobCreatePayload'
    workflowJob?: { __typename?: 'WorkflowJobType'; id: string } | null
  } | null
}

export type WorkloadDefinitionFragmentFragment = {
  __typename?: 'WorkloadDefinitionType'
  id: string
  name: string
  slug: string
  environmentVariables: any
  registryIsConfigured: boolean
  memoryLimit: string
  metricsPort?: number | null
  metricsPath?: string | null
  livenessPort?: number | null
  livenessPath?: string | null
  readinessPort?: number | null
  readinessPath?: string | null
  createdBy?: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
  } | null
  fileSystems: {
    __typename?: 'FileSystemTypeConnection'
    edges: Array<{
      __typename?: 'FileSystemTypeEdge'
      node?: {
        __typename?: 'FileSystemType'
        id: string
        slug: string
        capacity: string
      } | null
    } | null>
  }
  ingressrouteSet: {
    __typename?: 'IngressRouteTypeConnection'
    edges: Array<{
      __typename?: 'IngressRouteTypeEdge'
      node?: {
        __typename?: 'IngressRouteType'
        id: string
        url: string
        workloadRun?: {
          __typename?: 'WorkloadRunType'
          id: string
          namespace?: string | null
        } | null
      } | null
    } | null>
  }
}

export type WorkloadDefinitionQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type WorkloadDefinitionQuery = {
  __typename?: 'Query'
  workloadDefinition?: {
    __typename?: 'WorkloadDefinitionType'
    id: string
    name: string
    slug: string
    environmentVariables: any
    registryIsConfigured: boolean
    memoryLimit: string
    metricsPort?: number | null
    metricsPath?: string | null
    livenessPort?: number | null
    livenessPath?: string | null
    readinessPort?: number | null
    readinessPath?: string | null
    createdBy?: {
      __typename?: 'UserType'
      id: string
      firstName: string
      lastName: string
    } | null
    fileSystems: {
      __typename?: 'FileSystemTypeConnection'
      edges: Array<{
        __typename?: 'FileSystemTypeEdge'
        node?: {
          __typename?: 'FileSystemType'
          id: string
          slug: string
          capacity: string
        } | null
      } | null>
    }
    ingressrouteSet: {
      __typename?: 'IngressRouteTypeConnection'
      edges: Array<{
        __typename?: 'IngressRouteTypeEdge'
        node?: {
          __typename?: 'IngressRouteType'
          id: string
          url: string
          workloadRun?: {
            __typename?: 'WorkloadRunType'
            id: string
            namespace?: string | null
          } | null
        } | null
      } | null>
    }
  } | null
}

export type WorkloadDefinitionsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}>

export type WorkloadDefinitionsQuery = {
  __typename?: 'Query'
  workloadDefinitions?: {
    __typename?: 'WorkloadDefinitionTypeConnection'
    edges: Array<{
      __typename?: 'WorkloadDefinitionTypeEdge'
      node?: {
        __typename?: 'WorkloadDefinitionType'
        id: string
        name: string
        slug: string
        environmentVariables: any
        registryIsConfigured: boolean
        memoryLimit: string
        metricsPort?: number | null
        metricsPath?: string | null
        livenessPort?: number | null
        livenessPath?: string | null
        readinessPort?: number | null
        readinessPath?: string | null
        createdBy?: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
        } | null
        fileSystems: {
          __typename?: 'FileSystemTypeConnection'
          edges: Array<{
            __typename?: 'FileSystemTypeEdge'
            node?: {
              __typename?: 'FileSystemType'
              id: string
              slug: string
              capacity: string
            } | null
          } | null>
        }
        ingressrouteSet: {
          __typename?: 'IngressRouteTypeConnection'
          edges: Array<{
            __typename?: 'IngressRouteTypeEdge'
            node?: {
              __typename?: 'IngressRouteType'
              id: string
              url: string
              workloadRun?: {
                __typename?: 'WorkloadRunType'
                id: string
                namespace?: string | null
              } | null
            } | null
          } | null>
        }
      } | null
    } | null>
  } | null
}

export type WorkloadDefinitionUpdateCreateMutationVariables = Exact<{
  input: WorkloadDefinitionUpdateCreateInput
}>

export type WorkloadDefinitionUpdateCreateMutation = {
  __typename?: 'Mutation'
  workloadDefinitionCreateUpdate?: {
    __typename?: 'WorkloadDefinitionUpdateCreatePayload'
    workloadDefinition?: {
      __typename?: 'WorkloadDefinitionType'
      id: string
      name: string
      slug: string
      environmentVariables: any
      registryIsConfigured: boolean
      memoryLimit: string
      metricsPort?: number | null
      metricsPath?: string | null
      livenessPort?: number | null
      livenessPath?: string | null
      readinessPort?: number | null
      readinessPath?: string | null
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
      } | null
      fileSystems: {
        __typename?: 'FileSystemTypeConnection'
        edges: Array<{
          __typename?: 'FileSystemTypeEdge'
          node?: {
            __typename?: 'FileSystemType'
            id: string
            slug: string
            capacity: string
          } | null
        } | null>
      }
      ingressrouteSet: {
        __typename?: 'IngressRouteTypeConnection'
        edges: Array<{
          __typename?: 'IngressRouteTypeEdge'
          node?: {
            __typename?: 'IngressRouteType'
            id: string
            url: string
            workloadRun?: {
              __typename?: 'WorkloadRunType'
              id: string
              namespace?: string | null
            } | null
          } | null
        } | null>
      }
    } | null
  } | null
}

export type WorkloadDefinitionDeleteMutationVariables = Exact<{
  input: WorkloadDefinitionDeleteInput
}>

export type WorkloadDefinitionDeleteMutation = {
  __typename?: 'Mutation'
  workloadDefinitionDelete?: {
    __typename?: 'WorkloadDefinitionDeletePayload'
    ok: boolean
  } | null
}

export type WorkloadDefinitionUnarchiveMutationVariables = Exact<{
  input: WorkloadDefinitionUnarchiveInput
}>

export type WorkloadDefinitionUnarchiveMutation = {
  __typename?: 'Mutation'
  workloadDefinitionUnarchive?: {
    __typename?: 'WorkloadDefinitionUnarchivePayload'
    ok: boolean
  } | null
}

export type WorkloadRunFragmentFragment = {
  __typename?: 'WorkloadRunType'
  pk?: string | null
  id: string
  createdAt: any
  updatedAt: any
  workloadType: string
  runNumber: number
  lifecycleStatus: WorkloadRunLifecycleStatus
  namespace?: string | null
  workloadRunScale: number
  containerFullImagePath: string
  containerCommand?: string | null
  containerArgs?: string | null
  containerPorts: any
  workloadNodeName?: string | null
  serviceName?: string | null
  servicePort?: number | null
  workloadReplicas: number
  statusMessage?: string | null
  ingressRouteMatch?: string | null
  requireTeamHardware: boolean
  stickySessions: boolean
  team?: { __typename?: 'TeamType'; id: string } | null
  createdBy?: {
    __typename?: 'UserType'
    id: string
    firstName: string
    lastName: string
    username: string
  } | null
  containerHardwareRequirements: Array<{
    __typename?: 'ContainerHardwareRequirementsType'
    id: string
    amountOfHardwareModels: number
    hardwareModel: { __typename?: 'HardwareModelType'; modelName: string }
  }>
  containerRuntimeRequirements?: {
    __typename?: 'ContainerRuntimeRequirementsType'
    id: string
    label: string
  } | null
  ingressrouteSet: {
    __typename?: 'IngressRouteTypeConnection'
    edges: Array<{
      __typename?: 'IngressRouteTypeEdge'
      node?: { __typename?: 'IngressRouteType'; url: string } | null
    } | null>
  }
}

export type WorkloadRunQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type WorkloadRunQuery = {
  __typename?: 'Query'
  workloadRun?: {
    __typename?: 'WorkloadRunType'
    pk?: string | null
    id: string
    createdAt: any
    updatedAt: any
    workloadType: string
    runNumber: number
    lifecycleStatus: WorkloadRunLifecycleStatus
    namespace?: string | null
    workloadRunScale: number
    containerFullImagePath: string
    containerCommand?: string | null
    containerArgs?: string | null
    containerPorts: any
    workloadNodeName?: string | null
    serviceName?: string | null
    servicePort?: number | null
    workloadReplicas: number
    statusMessage?: string | null
    ingressRouteMatch?: string | null
    requireTeamHardware: boolean
    stickySessions: boolean
    team?: { __typename?: 'TeamType'; id: string } | null
    createdBy?: {
      __typename?: 'UserType'
      id: string
      firstName: string
      lastName: string
      username: string
    } | null
    containerHardwareRequirements: Array<{
      __typename?: 'ContainerHardwareRequirementsType'
      id: string
      amountOfHardwareModels: number
      hardwareModel: { __typename?: 'HardwareModelType'; modelName: string }
    }>
    containerRuntimeRequirements?: {
      __typename?: 'ContainerRuntimeRequirementsType'
      id: string
      label: string
    } | null
    ingressrouteSet: {
      __typename?: 'IngressRouteTypeConnection'
      edges: Array<{
        __typename?: 'IngressRouteTypeEdge'
        node?: { __typename?: 'IngressRouteType'; url: string } | null
      } | null>
    }
  } | null
}

export type WorkloadRunsQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  workloadDefinitionId?: InputMaybe<Scalars['ID']['input']>
  lifecycleStatus?: InputMaybe<
    Array<WorkloadRunLifecycleStatus> | WorkloadRunLifecycleStatus
  >
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}>

export type WorkloadRunsQuery = {
  __typename?: 'Query'
  workloadRuns?: {
    __typename?: 'WorkloadRunTypeConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'WorkloadRunTypeEdge'
      node?: {
        __typename?: 'WorkloadRunType'
        pk?: string | null
        id: string
        createdAt: any
        updatedAt: any
        workloadType: string
        runNumber: number
        lifecycleStatus: WorkloadRunLifecycleStatus
        namespace?: string | null
        workloadRunScale: number
        containerFullImagePath: string
        containerCommand?: string | null
        containerArgs?: string | null
        containerPorts: any
        workloadNodeName?: string | null
        serviceName?: string | null
        servicePort?: number | null
        workloadReplicas: number
        statusMessage?: string | null
        ingressRouteMatch?: string | null
        requireTeamHardware: boolean
        stickySessions: boolean
        team?: { __typename?: 'TeamType'; id: string } | null
        createdBy?: {
          __typename?: 'UserType'
          id: string
          firstName: string
          lastName: string
          username: string
        } | null
        containerHardwareRequirements: Array<{
          __typename?: 'ContainerHardwareRequirementsType'
          id: string
          amountOfHardwareModels: number
          hardwareModel: { __typename?: 'HardwareModelType'; modelName: string }
        }>
        containerRuntimeRequirements?: {
          __typename?: 'ContainerRuntimeRequirementsType'
          id: string
          label: string
        } | null
        ingressrouteSet: {
          __typename?: 'IngressRouteTypeConnection'
          edges: Array<{
            __typename?: 'IngressRouteTypeEdge'
            node?: { __typename?: 'IngressRouteType'; url: string } | null
          } | null>
        }
      } | null
    } | null>
  } | null
}

export type WorkloadRuntimeCapacityQueryVariables = Exact<{
  containerRuntimeId: Scalars['ID']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
}>

export type WorkloadRuntimeCapacityQuery = {
  __typename?: 'Query'
  workloadRuntimeCapacity: {
    __typename?: 'WorkloadRuntimeCapacityType'
    maximumScale?: number | null
    singleInstanceCapacity?: number | null
  }
}

export type WorkloadRunUpdateCreateMutationVariables = Exact<{
  input: WorkloadRunUpdateCreateInput
}>

export type WorkloadRunUpdateCreateMutation = {
  __typename?: 'Mutation'
  workloadRunCreateUpdate?: {
    __typename?: 'WorkloadRunUpdateCreatePayload'
    workloadRun?: {
      __typename?: 'WorkloadRunType'
      pk?: string | null
      id: string
      createdAt: any
      updatedAt: any
      workloadType: string
      runNumber: number
      lifecycleStatus: WorkloadRunLifecycleStatus
      namespace?: string | null
      workloadRunScale: number
      containerFullImagePath: string
      containerCommand?: string | null
      containerArgs?: string | null
      containerPorts: any
      workloadNodeName?: string | null
      serviceName?: string | null
      servicePort?: number | null
      workloadReplicas: number
      statusMessage?: string | null
      ingressRouteMatch?: string | null
      requireTeamHardware: boolean
      stickySessions: boolean
      team?: { __typename?: 'TeamType'; id: string } | null
      createdBy?: {
        __typename?: 'UserType'
        id: string
        firstName: string
        lastName: string
        username: string
      } | null
      containerHardwareRequirements: Array<{
        __typename?: 'ContainerHardwareRequirementsType'
        id: string
        amountOfHardwareModels: number
        hardwareModel: { __typename?: 'HardwareModelType'; modelName: string }
      }>
      containerRuntimeRequirements?: {
        __typename?: 'ContainerRuntimeRequirementsType'
        id: string
        label: string
      } | null
      ingressrouteSet: {
        __typename?: 'IngressRouteTypeConnection'
        edges: Array<{
          __typename?: 'IngressRouteTypeEdge'
          node?: { __typename?: 'IngressRouteType'; url: string } | null
        } | null>
      }
    } | null
  } | null
}

export type KubeconfigQueryVariables = Exact<{ [key: string]: never }>

export type KubeconfigQuery = {
  __typename?: 'Query'
  kubeconfig: { __typename?: 'KubeconfigType'; kubeconfig: string }
}

export const ClusterFragmentFragmentDoc = `
    fragment ClusterFragment on ClusterType {
  id
  pk
  createdAt
  updatedAt
  slug
  masterNodes {
    edges {
      node {
        id
        pk
        slug
      }
    }
  }
  workerNodes {
    edges {
      node {
        id
        pk
        slug
      }
    }
  }
}
    `
export const ContainerHardwareRequirementsFragmentFragmentDoc = `
    fragment ContainerHardwareRequirementsFragment on ContainerHardwareRequirementsType {
  id
  pk
  createdAt
  updatedAt
  hardwareModel {
    manufacturer {
      slug
    }
    modelName
    modelIdentifier
  }
  amountOfHardwareModels
}
    `
export const FileSystemFragmentFragmentDoc = `
    fragment FileSystemFragment on FileSystemType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
  status
  capacity
  createdBy {
    id
    firstName
    lastName
    username
  }
  team {
    name
  }
  errorMessage
}
    `
export const FlavorFragmentFragmentDoc = `
    fragment FlavorFragment on OpenStackFlavorType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
}
    `
export const HardwareModelFragmentFragmentDoc = `
    fragment HardwareModelFragment on HardwareModelType {
  id
  pk
  createdAt
  updatedAt
  manufacturer {
    slug
  }
  modelName
  modelIdentifier
}
    `
export const HardwareTagFragmentFragmentDoc = `
    fragment HardwareTagFragment on HardwareTagType {
  id
  pk
  createdAt
  updatedAt
  slug
}
    `
export const HardwareFragmentFragmentDoc = `
    fragment HardwareFragment on HardwareType {
  id
  pk
  createdAt
  updatedAt
  teamName
  slug
  macAddress
  serialNumber
  dnsRecord
  isHealthy
  isProvisioning
  isQuarantined
  canBeReserved
  isReserved
  hardwareModel {
    modelName
    modelIdentifier
  }
  availableForReservation
}
    `
export const IngressRouteFragmentFragmentDoc = `
    fragment IngressRouteFragment on IngressRouteType {
  id
  url
  workloadRun {
    id
    namespace
  }
}
    `
export const OpenStackImageFragmentFragmentDoc = `
    fragment OpenStackImageFragment on OpenStackImageType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
}
    `
export const ReservationFragmentFragmentDoc = `
    fragment ReservationFragment on ReservationType {
  id
  pk
  createdAt
  updatedAt
  createdBy {
    id
    firstName
    lastName
    username
  }
  team {
    name
  }
  requestedHardwareModel {
    modelName
    modelIdentifier
  }
  requestedAmountOfHardwareModel
  reservedHardware {
    id
    pk
    slug
  }
  status
  statusMessage
  startedAt
  endedAt
}
    `
export const ServerFragmentFragmentDoc = `
    fragment ServerFragment on OpenStackServerType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
  status
  openstackImage {
    name
    defaultSshUser
  }
  openstackFlavor {
    name
  }
  openstackFloatingIp
  createdBy {
    id
    firstName
    lastName
    username
  }
  computeHost
  errorMessage
}
    `
export const SshKeyFragmentFragmentDoc = `
    fragment SSHKeyFragment on SSHKeyType {
  id
  pk
  createdAt
  name
  key
  owner {
    id
  }
}
    `
export const TeamFragmentFragmentDoc = `
    fragment TeamFragment on TeamType {
  id
  pk
  createdAt
  updatedAt
  name
  slug
  isActive
  isKubernetesEnabled
  isHardwareEnabled
  isStorageEnabled
  isVmEnabled
}
    `
export const BasicUserFragmentFragmentDoc = `
    fragment BasicUserFragment on UserType {
  id
  pk
  username
  email
  firstName
  lastName
  isSuperuser
}
    `
export const WorkflowJobFragmentFragmentDoc = `
    fragment WorkflowJobFragment on WorkflowJobType {
  id
}
    `
export const WorkloadDefinitionFragmentFragmentDoc = `
    fragment WorkloadDefinitionFragment on WorkloadDefinitionType {
  id
  name
  slug
  createdBy {
    id
    firstName
    lastName
  }
  environmentVariables
  registryIsConfigured
  fileSystems {
    edges {
      node {
        id
        slug
        capacity
      }
    }
  }
  memoryLimit
  ingressrouteSet {
    edges {
      node {
        id
        url
        workloadRun {
          id
          namespace
        }
      }
    }
  }
  metricsPort
  metricsPath
  livenessPort
  livenessPath
  readinessPort
  readinessPath
}
    `
export const WorkloadRunFragmentFragmentDoc = `
    fragment WorkloadRunFragment on WorkloadRunType {
  pk
  id
  createdAt
  updatedAt
  team {
    id
  }
  createdBy {
    id
    firstName
    lastName
    username
  }
  containerHardwareRequirements {
    id
    hardwareModel {
      modelName
    }
    amountOfHardwareModels
  }
  containerRuntimeRequirements {
    id
    label
  }
  ingressrouteSet {
    edges {
      node {
        url
      }
    }
  }
  workloadType
  runNumber
  lifecycleStatus
  namespace
  workloadRunScale
  containerFullImagePath
  containerCommand
  containerArgs
  containerPorts
  workloadNodeName
  serviceName
  servicePort
  workloadReplicas
  statusMessage
  ingressRouteMatch
  requireTeamHardware
  stickySessions
}
    `
export const LoginDocument = `
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token {
      id
      user {
        username
      }
      key
      keyName
      createdAt
    }
  }
}
    `

export const useLoginMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LoginMutation,
    TError,
    LoginMutationVariables,
    TContext
  >
) => {
  return useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
    ['login'],
    useFetchData<LoginMutation, LoginMutationVariables>(LoginDocument),
    options
  )
}

export const LogoutDocument = `
    mutation logout($input: LogoutInput!) {
  logout(input: $input) {
    ok
  }
}
    `

export const useLogoutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LogoutMutation,
    TError,
    LogoutMutationVariables,
    TContext
  >
) => {
  return useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
    ['logout'],
    useFetchData<LogoutMutation, LogoutMutationVariables>(LogoutDocument),
    options
  )
}

export const SignupDocument = `
    mutation signup($input: SignupInput!) {
  signup(input: $input) {
    token {
      id
      user {
        username
      }
      key
      keyName
      createdAt
    }
  }
}
    `

export const useSignupMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SignupMutation,
    TError,
    SignupMutationVariables,
    TContext
  >
) => {
  return useMutation<SignupMutation, TError, SignupMutationVariables, TContext>(
    ['signup'],
    useFetchData<SignupMutation, SignupMutationVariables>(SignupDocument),
    options
  )
}

export const PasswordChangeDocument = `
    mutation passwordChange($input: PasswordChangeInput!) {
  passwordChange(input: $input) {
    ok
  }
}
    `

export const usePasswordChangeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PasswordChangeMutation,
    TError,
    PasswordChangeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    PasswordChangeMutation,
    TError,
    PasswordChangeMutationVariables,
    TContext
  >(
    ['passwordChange'],
    useFetchData<PasswordChangeMutation, PasswordChangeMutationVariables>(
      PasswordChangeDocument
    ),
    options
  )
}

export const ClustersDocument = `
    query clusters($teamId: ID!, $first: Int, $offset: Int, $orderBy: String) {
  clusters(teamId: $teamId, first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...ClusterFragment
      }
    }
  }
}
    ${ClusterFragmentFragmentDoc}`

export const useClustersQuery = <TData = ClustersQuery, TError = unknown>(
  variables: ClustersQueryVariables,
  options?: UseQueryOptions<ClustersQuery, TError, TData>
) => {
  return useQuery<ClustersQuery, TError, TData>(
    ['clusters', variables],
    useFetchData<ClustersQuery, ClustersQueryVariables>(ClustersDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const ContainerHardwareRequirementsDocument = `
    query containerHardwareRequirements($teamId: ID, $first: Int, $offset: Int) {
  containerHardwareRequirements(teamId: $teamId, first: $first, offset: $offset) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...ContainerHardwareRequirementsFragment
      }
    }
  }
}
    ${ContainerHardwareRequirementsFragmentFragmentDoc}`

export const useContainerHardwareRequirementsQuery = <
  TData = ContainerHardwareRequirementsQuery,
  TError = unknown,
>(
  variables?: ContainerHardwareRequirementsQueryVariables,
  options?: UseQueryOptions<ContainerHardwareRequirementsQuery, TError, TData>
) => {
  return useQuery<ContainerHardwareRequirementsQuery, TError, TData>(
    variables === undefined
      ? ['containerHardwareRequirements']
      : ['containerHardwareRequirements', variables],
    useFetchData<
      ContainerHardwareRequirementsQuery,
      ContainerHardwareRequirementsQueryVariables
    >(ContainerHardwareRequirementsDocument).bind(null, variables),
    options
  )
}

export const ContainerRuntimeRequirementsDocument = `
    query containerRuntimeRequirements {
  containerRuntimeRequirements {
    edges {
      node {
        id
        label
        availability
        resourceName
      }
    }
  }
}
    `

export const useContainerRuntimeRequirementsQuery = <
  TData = ContainerRuntimeRequirementsQuery,
  TError = unknown,
>(
  variables?: ContainerRuntimeRequirementsQueryVariables,
  options?: UseQueryOptions<ContainerRuntimeRequirementsQuery, TError, TData>
) => {
  return useQuery<ContainerRuntimeRequirementsQuery, TError, TData>(
    variables === undefined
      ? ['containerRuntimeRequirements']
      : ['containerRuntimeRequirements', variables],
    useFetchData<
      ContainerRuntimeRequirementsQuery,
      ContainerRuntimeRequirementsQueryVariables
    >(ContainerRuntimeRequirementsDocument).bind(null, variables),
    options
  )
}

export const FileSystemDocument = `
    query fileSystem($id: ID!) {
  fileSystem(id: $id) {
    ...FileSystemFragment
  }
}
    ${FileSystemFragmentFragmentDoc}`

export const useFileSystemQuery = <TData = FileSystemQuery, TError = unknown>(
  variables: FileSystemQueryVariables,
  options?: UseQueryOptions<FileSystemQuery, TError, TData>
) => {
  return useQuery<FileSystemQuery, TError, TData>(
    ['fileSystem', variables],
    useFetchData<FileSystemQuery, FileSystemQueryVariables>(
      FileSystemDocument
    ).bind(null, variables),
    options
  )
}

export const FileSystemsDocument = `
    query fileSystems($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $status: [FileSystemStatus!], $name: String, $createdBy: String) {
  fileSystems(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    status_In: $status
    name_Icontains: $name
    createdBy_Username_Icontains: $createdBy
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...FileSystemFragment
      }
    }
  }
}
    ${FileSystemFragmentFragmentDoc}`

export const useFileSystemsQuery = <TData = FileSystemsQuery, TError = unknown>(
  variables?: FileSystemsQueryVariables,
  options?: UseQueryOptions<FileSystemsQuery, TError, TData>
) => {
  return useQuery<FileSystemsQuery, TError, TData>(
    variables === undefined ? ['fileSystems'] : ['fileSystems', variables],
    useFetchData<FileSystemsQuery, FileSystemsQueryVariables>(
      FileSystemsDocument
    ).bind(null, variables),
    options
  )
}

export const FileSystemCreateDocument = `
    mutation fileSystemCreate($input: FileSystemCreateInput!) {
  fileSystemCreate(input: $input) {
    fileSystem {
      ...FileSystemFragment
    }
  }
}
    ${FileSystemFragmentFragmentDoc}`

export const useFileSystemCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    FileSystemCreateMutation,
    TError,
    FileSystemCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    FileSystemCreateMutation,
    TError,
    FileSystemCreateMutationVariables,
    TContext
  >(
    ['fileSystemCreate'],
    useFetchData<FileSystemCreateMutation, FileSystemCreateMutationVariables>(
      FileSystemCreateDocument
    ),
    options
  )
}

export const FileSystemDeleteDocument = `
    mutation fileSystemDelete($input: FileSystemDeleteInput!) {
  fileSystemDelete(input: $input) {
    fileSystem {
      ...FileSystemFragment
    }
  }
}
    ${FileSystemFragmentFragmentDoc}`

export const useFileSystemDeleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    FileSystemDeleteMutation,
    TError,
    FileSystemDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    FileSystemDeleteMutation,
    TError,
    FileSystemDeleteMutationVariables,
    TContext
  >(
    ['fileSystemDelete'],
    useFetchData<FileSystemDeleteMutation, FileSystemDeleteMutationVariables>(
      FileSystemDeleteDocument
    ),
    options
  )
}

export const FlavorsDocument = `
    query flavors($first: Int, $offset: Int, $orderBy: String, $availability: String) {
  flavors(
    first: $first
    offset: $offset
    orderBy: $orderBy
    availability: $availability
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...FlavorFragment
      }
    }
  }
}
    ${FlavorFragmentFragmentDoc}`

export const useFlavorsQuery = <TData = FlavorsQuery, TError = unknown>(
  variables?: FlavorsQueryVariables,
  options?: UseQueryOptions<FlavorsQuery, TError, TData>
) => {
  return useQuery<FlavorsQuery, TError, TData>(
    variables === undefined ? ['flavors'] : ['flavors', variables],
    useFetchData<FlavorsQuery, FlavorsQueryVariables>(FlavorsDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const HardwareModelsDocument = `
    query hardwareModels($teamId: ID!, $first: Int, $offset: Int) {
  hardwareModels(teamId: $teamId, first: $first, offset: $offset) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...HardwareModelFragment
      }
    }
  }
}
    ${HardwareModelFragmentFragmentDoc}`

export const useHardwareModelsQuery = <
  TData = HardwareModelsQuery,
  TError = unknown,
>(
  variables: HardwareModelsQueryVariables,
  options?: UseQueryOptions<HardwareModelsQuery, TError, TData>
) => {
  return useQuery<HardwareModelsQuery, TError, TData>(
    ['hardwareModels', variables],
    useFetchData<HardwareModelsQuery, HardwareModelsQueryVariables>(
      HardwareModelsDocument
    ).bind(null, variables),
    options
  )
}

export const HardwareTagsDocument = `
    query hardwareTags($teamId: ID!, $first: Int, $offset: Int) {
  hardwareTags(teamId: $teamId, first: $first, offset: $offset) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...HardwareTagFragment
      }
    }
  }
}
    ${HardwareTagFragmentFragmentDoc}`

export const useHardwareTagsQuery = <
  TData = HardwareTagsQuery,
  TError = unknown,
>(
  variables: HardwareTagsQueryVariables,
  options?: UseQueryOptions<HardwareTagsQuery, TError, TData>
) => {
  return useQuery<HardwareTagsQuery, TError, TData>(
    ['hardwareTags', variables],
    useFetchData<HardwareTagsQuery, HardwareTagsQueryVariables>(
      HardwareTagsDocument
    ).bind(null, variables),
    options
  )
}

export const HardwaresDocument = `
    query hardwares($teamId: ID!, $first: Int, $offset: Int, $orderBy: String, $name: String, $isHealthy: Boolean, $isQuarantined: Boolean, $hardwareTagIds: [ID], $teamName: String, $hardwareModelName: String) {
  hardwares(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    slug_Icontains: $name
    isHealthy: $isHealthy
    isQuarantined: $isQuarantined
    hardwareTagIds: $hardwareTagIds
    team_Name_Icontains: $teamName
    hardwareModel_ModelName_Icontains: $hardwareModelName
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...HardwareFragment
      }
    }
  }
}
    ${HardwareFragmentFragmentDoc}`

export const useHardwaresQuery = <TData = HardwaresQuery, TError = unknown>(
  variables: HardwaresQueryVariables,
  options?: UseQueryOptions<HardwaresQuery, TError, TData>
) => {
  return useQuery<HardwaresQuery, TError, TData>(
    ['hardwares', variables],
    useFetchData<HardwaresQuery, HardwaresQueryVariables>(
      HardwaresDocument
    ).bind(null, variables),
    options
  )
}

export const IngressRoutesDocument = `
    query ingressRoutes($workloadDefinitionId: ID!, $first: Int, $offset: Int) {
  ingressRoutes(
    workloadDefinitionId: $workloadDefinitionId
    first: $first
    offset: $offset
  ) {
    edges {
      node {
        ...IngressRouteFragment
      }
    }
  }
}
    ${IngressRouteFragmentFragmentDoc}`

export const useIngressRoutesQuery = <
  TData = IngressRoutesQuery,
  TError = unknown,
>(
  variables: IngressRoutesQueryVariables,
  options?: UseQueryOptions<IngressRoutesQuery, TError, TData>
) => {
  return useQuery<IngressRoutesQuery, TError, TData>(
    ['ingressRoutes', variables],
    useFetchData<IngressRoutesQuery, IngressRoutesQueryVariables>(
      IngressRoutesDocument
    ).bind(null, variables),
    options
  )
}

export const IngressRouteCreateDocument = `
    mutation ingressRouteCreate($input: IngressRouteCreateInput!) {
  ingressRouteCreate(input: $input) {
    ok
  }
}
    `

export const useIngressRouteCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    IngressRouteCreateMutation,
    TError,
    IngressRouteCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    IngressRouteCreateMutation,
    TError,
    IngressRouteCreateMutationVariables,
    TContext
  >(
    ['ingressRouteCreate'],
    useFetchData<
      IngressRouteCreateMutation,
      IngressRouteCreateMutationVariables
    >(IngressRouteCreateDocument),
    options
  )
}

export const IngressRouteDeleteDocument = `
    mutation ingressRouteDelete($input: IngressRouteDeleteInput!) {
  ingressRouteDelete(input: $input) {
    ok
  }
}
    `

export const useIngressRouteDeleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    IngressRouteDeleteMutation,
    TError,
    IngressRouteDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    IngressRouteDeleteMutation,
    TError,
    IngressRouteDeleteMutationVariables,
    TContext
  >(
    ['ingressRouteDelete'],
    useFetchData<
      IngressRouteDeleteMutation,
      IngressRouteDeleteMutationVariables
    >(IngressRouteDeleteDocument),
    options
  )
}

export const IngressRouteAssignDocument = `
    mutation ingressRouteAssign($input: IngressRouteAssignInput!) {
  ingressRouteAssign(input: $input) {
    ok
  }
}
    `

export const useIngressRouteAssignMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    IngressRouteAssignMutation,
    TError,
    IngressRouteAssignMutationVariables,
    TContext
  >
) => {
  return useMutation<
    IngressRouteAssignMutation,
    TError,
    IngressRouteAssignMutationVariables,
    TContext
  >(
    ['ingressRouteAssign'],
    useFetchData<
      IngressRouteAssignMutation,
      IngressRouteAssignMutationVariables
    >(IngressRouteAssignDocument),
    options
  )
}

export const OpenStackImagesDocument = `
    query openStackImages($first: Int, $offset: Int, $orderBy: String, $availability: String) {
  openStackImages(
    first: $first
    offset: $offset
    orderBy: $orderBy
    availability: $availability
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...OpenStackImageFragment
      }
    }
  }
}
    ${OpenStackImageFragmentFragmentDoc}`

export const useOpenStackImagesQuery = <
  TData = OpenStackImagesQuery,
  TError = unknown,
>(
  variables?: OpenStackImagesQueryVariables,
  options?: UseQueryOptions<OpenStackImagesQuery, TError, TData>
) => {
  return useQuery<OpenStackImagesQuery, TError, TData>(
    variables === undefined
      ? ['openStackImages']
      : ['openStackImages', variables],
    useFetchData<OpenStackImagesQuery, OpenStackImagesQueryVariables>(
      OpenStackImagesDocument
    ).bind(null, variables),
    options
  )
}

export const ReservationsDocument = `
    query reservations($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $status: String, $createdBy: String, $team: String, $hardwareModel: String) {
  reservations(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    status: $status
    createdBy_Username_Icontains: $createdBy
    team_Name_Icontains: $team
    requestedHardwareModel_ModelIdentifier_Icontains: $hardwareModel
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...ReservationFragment
      }
    }
  }
}
    ${ReservationFragmentFragmentDoc}`

export const useReservationsQuery = <
  TData = ReservationsQuery,
  TError = unknown,
>(
  variables?: ReservationsQueryVariables,
  options?: UseQueryOptions<ReservationsQuery, TError, TData>
) => {
  return useQuery<ReservationsQuery, TError, TData>(
    variables === undefined ? ['reservations'] : ['reservations', variables],
    useFetchData<ReservationsQuery, ReservationsQueryVariables>(
      ReservationsDocument
    ).bind(null, variables),
    options
  )
}

export const CreateReservationDocument = `
    mutation createReservation($input: CreateReservationInput!) {
  createReservation(input: $input) {
    reservation {
      ...ReservationFragment
    }
  }
}
    ${ReservationFragmentFragmentDoc}`

export const useCreateReservationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateReservationMutation,
    TError,
    CreateReservationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateReservationMutation,
    TError,
    CreateReservationMutationVariables,
    TContext
  >(
    ['createReservation'],
    useFetchData<CreateReservationMutation, CreateReservationMutationVariables>(
      CreateReservationDocument
    ),
    options
  )
}

export const ReleaseReservationDocument = `
    mutation releaseReservation($input: ReleaseReservationInput!) {
  releaseReservation(input: $input) {
    reservation {
      ...ReservationFragment
    }
  }
}
    ${ReservationFragmentFragmentDoc}`

export const useReleaseReservationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ReleaseReservationMutation,
    TError,
    ReleaseReservationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ReleaseReservationMutation,
    TError,
    ReleaseReservationMutationVariables,
    TContext
  >(
    ['releaseReservation'],
    useFetchData<
      ReleaseReservationMutation,
      ReleaseReservationMutationVariables
    >(ReleaseReservationDocument),
    options
  )
}

export const ServerDocument = `
    query server($id: ID!) {
  server(id: $id) {
    ...ServerFragment
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerQuery = <TData = ServerQuery, TError = unknown>(
  variables: ServerQueryVariables,
  options?: UseQueryOptions<ServerQuery, TError, TData>
) => {
  return useQuery<ServerQuery, TError, TData>(
    ['server', variables],
    useFetchData<ServerQuery, ServerQueryVariables>(ServerDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const ServersDocument = `
    query servers($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $status: [OpenStackServerStatus!], $createdBy: String, $computeHost: String, $name: String, $ip: String) {
  servers(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    status_In: $status
    createdBy_Username_Icontains: $createdBy
    computeHost_Icontains: $computeHost
    name_Icontains: $name
    openstackFloatingIp_Icontains: $ip
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...ServerFragment
      }
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServersQuery = <TData = ServersQuery, TError = unknown>(
  variables?: ServersQueryVariables,
  options?: UseQueryOptions<ServersQuery, TError, TData>
) => {
  return useQuery<ServersQuery, TError, TData>(
    variables === undefined ? ['servers'] : ['servers', variables],
    useFetchData<ServersQuery, ServersQueryVariables>(ServersDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const ServerCreateDocument = `
    mutation serverCreate($input: ServerCreateInput!) {
  serverCreate(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerCreateMutation,
    TError,
    ServerCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerCreateMutation,
    TError,
    ServerCreateMutationVariables,
    TContext
  >(
    ['serverCreate'],
    useFetchData<ServerCreateMutation, ServerCreateMutationVariables>(
      ServerCreateDocument
    ),
    options
  )
}

export const ServerDeleteDocument = `
    mutation serverDelete($input: ServerDeleteInput!) {
  serverDelete(input: $input) {
    ok
  }
}
    `

export const useServerDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerDeleteMutation,
    TError,
    ServerDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerDeleteMutation,
    TError,
    ServerDeleteMutationVariables,
    TContext
  >(
    ['serverDelete'],
    useFetchData<ServerDeleteMutation, ServerDeleteMutationVariables>(
      ServerDeleteDocument
    ),
    options
  )
}

export const ServerStartDocument = `
    mutation serverStart($input: ServerStartInput!) {
  serverStart(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerStartMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerStartMutation,
    TError,
    ServerStartMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerStartMutation,
    TError,
    ServerStartMutationVariables,
    TContext
  >(
    ['serverStart'],
    useFetchData<ServerStartMutation, ServerStartMutationVariables>(
      ServerStartDocument
    ),
    options
  )
}

export const ServerShutoffDocument = `
    mutation serverShutoff($input: ServerShutoffInput!) {
  serverShutoff(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerShutoffMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ServerShutoffMutation,
    TError,
    ServerShutoffMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerShutoffMutation,
    TError,
    ServerShutoffMutationVariables,
    TContext
  >(
    ['serverShutoff'],
    useFetchData<ServerShutoffMutation, ServerShutoffMutationVariables>(
      ServerShutoffDocument
    ),
    options
  )
}

export const ServerSoftRebootDocument = `
    mutation serverSoftReboot($input: ServerSoftRebootInput!) {
  serverSoftReboot(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerSoftRebootMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ServerSoftRebootMutation,
    TError,
    ServerSoftRebootMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerSoftRebootMutation,
    TError,
    ServerSoftRebootMutationVariables,
    TContext
  >(
    ['serverSoftReboot'],
    useFetchData<ServerSoftRebootMutation, ServerSoftRebootMutationVariables>(
      ServerSoftRebootDocument
    ),
    options
  )
}

export const ServerHardRebootDocument = `
    mutation serverHardReboot($input: ServerHardRebootInput!) {
  serverHardReboot(input: $input) {
    server {
      ...ServerFragment
    }
  }
}
    ${ServerFragmentFragmentDoc}`

export const useServerHardRebootMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ServerHardRebootMutation,
    TError,
    ServerHardRebootMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ServerHardRebootMutation,
    TError,
    ServerHardRebootMutationVariables,
    TContext
  >(
    ['serverHardReboot'],
    useFetchData<ServerHardRebootMutation, ServerHardRebootMutationVariables>(
      ServerHardRebootDocument
    ),
    options
  )
}

export const SshKeyDocument = `
    query sshKey($id: ID!) {
  sshKey(id: $id) {
    ...SSHKeyFragment
  }
}
    ${SshKeyFragmentFragmentDoc}`

export const useSshKeyQuery = <TData = SshKeyQuery, TError = unknown>(
  variables: SshKeyQueryVariables,
  options?: UseQueryOptions<SshKeyQuery, TError, TData>
) => {
  return useQuery<SshKeyQuery, TError, TData>(
    ['sshKey', variables],
    useFetchData<SshKeyQuery, SshKeyQueryVariables>(SshKeyDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const SshKeysDocument = `
    query sshKeys {
  sshKeys {
    edges {
      node {
        ...SSHKeyFragment
      }
    }
  }
}
    ${SshKeyFragmentFragmentDoc}`

export const useSshKeysQuery = <TData = SshKeysQuery, TError = unknown>(
  variables?: SshKeysQueryVariables,
  options?: UseQueryOptions<SshKeysQuery, TError, TData>
) => {
  return useQuery<SshKeysQuery, TError, TData>(
    variables === undefined ? ['sshKeys'] : ['sshKeys', variables],
    useFetchData<SshKeysQuery, SshKeysQueryVariables>(SshKeysDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const SshKeyUpdateCreateDocument = `
    mutation sshKeyUpdateCreate($input: SSHKeyUpdateCreateInput!) {
  sshKeyUpdateCreate(input: $input) {
    sshKey {
      ...SSHKeyFragment
    }
  }
}
    ${SshKeyFragmentFragmentDoc}`

export const useSshKeyUpdateCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SshKeyUpdateCreateMutation,
    TError,
    SshKeyUpdateCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SshKeyUpdateCreateMutation,
    TError,
    SshKeyUpdateCreateMutationVariables,
    TContext
  >(
    ['sshKeyUpdateCreate'],
    useFetchData<
      SshKeyUpdateCreateMutation,
      SshKeyUpdateCreateMutationVariables
    >(SshKeyUpdateCreateDocument),
    options
  )
}

export const SshKeyDeleteDocument = `
    mutation sshKeyDelete($input: SSHKeyDeleteInput!) {
  sshKeyDelete(input: $input) {
    ok
  }
}
    `

export const useSshKeyDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SshKeyDeleteMutation,
    TError,
    SshKeyDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SshKeyDeleteMutation,
    TError,
    SshKeyDeleteMutationVariables,
    TContext
  >(
    ['sshKeyDelete'],
    useFetchData<SshKeyDeleteMutation, SshKeyDeleteMutationVariables>(
      SshKeyDeleteDocument
    ),
    options
  )
}

export const TeamDocument = `
    query team($id: ID!) {
  team(id: $id) {
    ...TeamFragment
    adminGroup {
      id
      name
      userSet {
        edges {
          node {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
    group {
      id
      name
      userSet {
        edges {
          node {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
}
    ${TeamFragmentFragmentDoc}`

export const useTeamQuery = <TData = TeamQuery, TError = unknown>(
  variables: TeamQueryVariables,
  options?: UseQueryOptions<TeamQuery, TError, TData>
) => {
  return useQuery<TeamQuery, TError, TData>(
    ['team', variables],
    useFetchData<TeamQuery, TeamQueryVariables>(TeamDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const TeamsDocument = `
    query teams($name: String) {
  teams(name: $name) {
    edges {
      node {
        ...TeamFragment
      }
    }
  }
}
    ${TeamFragmentFragmentDoc}`

export const useTeamsQuery = <TData = TeamsQuery, TError = unknown>(
  variables?: TeamsQueryVariables,
  options?: UseQueryOptions<TeamsQuery, TError, TData>
) => {
  return useQuery<TeamsQuery, TError, TData>(
    variables === undefined ? ['teams'] : ['teams', variables],
    useFetchData<TeamsQuery, TeamsQueryVariables>(TeamsDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const TeamUpdateCreateDocument = `
    mutation teamUpdateCreate($input: TeamUpdateCreateInput!) {
  teamUpdateCreate(input: $input) {
    team {
      ...TeamFragment
    }
  }
}
    ${TeamFragmentFragmentDoc}`

export const useTeamUpdateCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    TeamUpdateCreateMutation,
    TError,
    TeamUpdateCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamUpdateCreateMutation,
    TError,
    TeamUpdateCreateMutationVariables,
    TContext
  >(
    ['teamUpdateCreate'],
    useFetchData<TeamUpdateCreateMutation, TeamUpdateCreateMutationVariables>(
      TeamUpdateCreateDocument
    ),
    options
  )
}

export const TeamDeleteDocument = `
    mutation teamDelete($input: TeamDeleteInput!) {
  teamDelete(input: $input) {
    ok
  }
}
    `

export const useTeamDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TeamDeleteMutation,
    TError,
    TeamDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamDeleteMutation,
    TError,
    TeamDeleteMutationVariables,
    TContext
  >(
    ['teamDelete'],
    useFetchData<TeamDeleteMutation, TeamDeleteMutationVariables>(
      TeamDeleteDocument
    ),
    options
  )
}

export const TeamChangeUserGroupDocument = `
    mutation teamChangeUserGroup($input: TeamChangeUserGroupInput!) {
  teamChangeUserGroup(input: $input) {
    team {
      id
    }
  }
}
    `

export const useTeamChangeUserGroupMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    TeamChangeUserGroupMutation,
    TError,
    TeamChangeUserGroupMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamChangeUserGroupMutation,
    TError,
    TeamChangeUserGroupMutationVariables,
    TContext
  >(
    ['teamChangeUserGroup'],
    useFetchData<
      TeamChangeUserGroupMutation,
      TeamChangeUserGroupMutationVariables
    >(TeamChangeUserGroupDocument),
    options
  )
}

export const TeamAddUserDocument = `
    mutation teamAddUser($input: TeamAddUserInput!) {
  teamAddUser(input: $input) {
    team {
      id
    }
  }
}
    `

export const useTeamAddUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TeamAddUserMutation,
    TError,
    TeamAddUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamAddUserMutation,
    TError,
    TeamAddUserMutationVariables,
    TContext
  >(
    ['teamAddUser'],
    useFetchData<TeamAddUserMutation, TeamAddUserMutationVariables>(
      TeamAddUserDocument
    ),
    options
  )
}

export const TeamRemoveUserDocument = `
    mutation teamRemoveUser($input: TeamRemoveUserInput!) {
  teamRemoveUser(input: $input) {
    team {
      id
    }
  }
}
    `

export const useTeamRemoveUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TeamRemoveUserMutation,
    TError,
    TeamRemoveUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TeamRemoveUserMutation,
    TError,
    TeamRemoveUserMutationVariables,
    TContext
  >(
    ['teamRemoveUser'],
    useFetchData<TeamRemoveUserMutation, TeamRemoveUserMutationVariables>(
      TeamRemoveUserDocument
    ),
    options
  )
}

export const UsersDocument = `
    query users($search: String) {
  users(search: $search) {
    edges {
      node {
        ...BasicUserFragment
      }
    }
  }
}
    ${BasicUserFragmentFragmentDoc}`

export const useUsersQuery = <TData = UsersQuery, TError = unknown>(
  variables?: UsersQueryVariables,
  options?: UseQueryOptions<UsersQuery, TError, TData>
) => {
  return useQuery<UsersQuery, TError, TData>(
    variables === undefined ? ['users'] : ['users', variables],
    useFetchData<UsersQuery, UsersQueryVariables>(UsersDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const WhoamiDocument = `
    query whoami {
  whoami {
    ...BasicUserFragment
  }
}
    ${BasicUserFragmentFragmentDoc}`

export const useWhoamiQuery = <TData = WhoamiQuery, TError = unknown>(
  variables?: WhoamiQueryVariables,
  options?: UseQueryOptions<WhoamiQuery, TError, TData>
) => {
  return useQuery<WhoamiQuery, TError, TData>(
    variables === undefined ? ['whoami'] : ['whoami', variables],
    useFetchData<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument).bind(
      null,
      variables
    ),
    options
  )
}

export const UserUpdateDocument = `
    mutation userUpdate($input: UserUpdateInput!) {
  userUpdate(input: $input) {
    user {
      ...BasicUserFragment
    }
  }
}
    ${BasicUserFragmentFragmentDoc}`

export const useUserUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UserUpdateMutation,
    TError,
    UserUpdateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UserUpdateMutation,
    TError,
    UserUpdateMutationVariables,
    TContext
  >(
    ['userUpdate'],
    useFetchData<UserUpdateMutation, UserUpdateMutationVariables>(
      UserUpdateDocument
    ),
    options
  )
}

export const WorkflowJobCreateDocument = `
    mutation workflowJobCreate($input: WorkflowJobCreateInput!) {
  workflowJobCreate(input: $input) {
    workflowJob {
      ...WorkflowJobFragment
    }
  }
}
    ${WorkflowJobFragmentFragmentDoc}`

export const useWorkflowJobCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkflowJobCreateMutation,
    TError,
    WorkflowJobCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkflowJobCreateMutation,
    TError,
    WorkflowJobCreateMutationVariables,
    TContext
  >(
    ['workflowJobCreate'],
    useFetchData<WorkflowJobCreateMutation, WorkflowJobCreateMutationVariables>(
      WorkflowJobCreateDocument
    ),
    options
  )
}

export const WorkloadDefinitionDocument = `
    query workloadDefinition($id: ID!) {
  workloadDefinition(id: $id) {
    ...WorkloadDefinitionFragment
  }
}
    ${WorkloadDefinitionFragmentFragmentDoc}`

export const useWorkloadDefinitionQuery = <
  TData = WorkloadDefinitionQuery,
  TError = unknown,
>(
  variables: WorkloadDefinitionQueryVariables,
  options?: UseQueryOptions<WorkloadDefinitionQuery, TError, TData>
) => {
  return useQuery<WorkloadDefinitionQuery, TError, TData>(
    ['workloadDefinition', variables],
    useFetchData<WorkloadDefinitionQuery, WorkloadDefinitionQueryVariables>(
      WorkloadDefinitionDocument
    ).bind(null, variables),
    options
  )
}

export const WorkloadDefinitionsDocument = `
    query workloadDefinitions($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $isArchived: Boolean, $slug: String) {
  workloadDefinitions(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    isArchived: $isArchived
    slug: $slug
  ) {
    edges {
      node {
        ...WorkloadDefinitionFragment
      }
    }
  }
}
    ${WorkloadDefinitionFragmentFragmentDoc}`

export const useWorkloadDefinitionsQuery = <
  TData = WorkloadDefinitionsQuery,
  TError = unknown,
>(
  variables?: WorkloadDefinitionsQueryVariables,
  options?: UseQueryOptions<WorkloadDefinitionsQuery, TError, TData>
) => {
  return useQuery<WorkloadDefinitionsQuery, TError, TData>(
    variables === undefined
      ? ['workloadDefinitions']
      : ['workloadDefinitions', variables],
    useFetchData<WorkloadDefinitionsQuery, WorkloadDefinitionsQueryVariables>(
      WorkloadDefinitionsDocument
    ).bind(null, variables),
    options
  )
}

export const WorkloadDefinitionUpdateCreateDocument = `
    mutation workloadDefinitionUpdateCreate($input: WorkloadDefinitionUpdateCreateInput!) {
  workloadDefinitionCreateUpdate(input: $input) {
    workloadDefinition {
      ...WorkloadDefinitionFragment
    }
  }
}
    ${WorkloadDefinitionFragmentFragmentDoc}`

export const useWorkloadDefinitionUpdateCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadDefinitionUpdateCreateMutation,
    TError,
    WorkloadDefinitionUpdateCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadDefinitionUpdateCreateMutation,
    TError,
    WorkloadDefinitionUpdateCreateMutationVariables,
    TContext
  >(
    ['workloadDefinitionUpdateCreate'],
    useFetchData<
      WorkloadDefinitionUpdateCreateMutation,
      WorkloadDefinitionUpdateCreateMutationVariables
    >(WorkloadDefinitionUpdateCreateDocument),
    options
  )
}

export const WorkloadDefinitionDeleteDocument = `
    mutation workloadDefinitionDelete($input: WorkloadDefinitionDeleteInput!) {
  workloadDefinitionDelete(input: $input) {
    ok
  }
}
    `

export const useWorkloadDefinitionDeleteMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadDefinitionDeleteMutation,
    TError,
    WorkloadDefinitionDeleteMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadDefinitionDeleteMutation,
    TError,
    WorkloadDefinitionDeleteMutationVariables,
    TContext
  >(
    ['workloadDefinitionDelete'],
    useFetchData<
      WorkloadDefinitionDeleteMutation,
      WorkloadDefinitionDeleteMutationVariables
    >(WorkloadDefinitionDeleteDocument),
    options
  )
}

export const WorkloadDefinitionUnarchiveDocument = `
    mutation workloadDefinitionUnarchive($input: WorkloadDefinitionUnarchiveInput!) {
  workloadDefinitionUnarchive(input: $input) {
    ok
  }
}
    `

export const useWorkloadDefinitionUnarchiveMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadDefinitionUnarchiveMutation,
    TError,
    WorkloadDefinitionUnarchiveMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadDefinitionUnarchiveMutation,
    TError,
    WorkloadDefinitionUnarchiveMutationVariables,
    TContext
  >(
    ['workloadDefinitionUnarchive'],
    useFetchData<
      WorkloadDefinitionUnarchiveMutation,
      WorkloadDefinitionUnarchiveMutationVariables
    >(WorkloadDefinitionUnarchiveDocument),
    options
  )
}

export const WorkloadRunDocument = `
    query workloadRun($id: ID!) {
  workloadRun(id: $id) {
    ...WorkloadRunFragment
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunQuery = <TData = WorkloadRunQuery, TError = unknown>(
  variables: WorkloadRunQueryVariables,
  options?: UseQueryOptions<WorkloadRunQuery, TError, TData>
) => {
  return useQuery<WorkloadRunQuery, TError, TData>(
    ['workloadRun', variables],
    useFetchData<WorkloadRunQuery, WorkloadRunQueryVariables>(
      WorkloadRunDocument
    ).bind(null, variables),
    options
  )
}

export const WorkloadRunsDocument = `
    query workloadRuns($teamId: ID, $first: Int, $offset: Int, $orderBy: String, $workloadDefinitionId: ID, $lifecycleStatus: [WorkloadRunLifecycleStatus!], $firstName: String, $lastName: String, $username: String) {
  workloadRuns(
    teamId: $teamId
    first: $first
    offset: $offset
    orderBy: $orderBy
    workloadDefinitionId: $workloadDefinitionId
    lifecycleStatus_In: $lifecycleStatus
    createdBy_FirstName_Icontains: $firstName
    createdBy_LastName_Icontains: $lastName
    createdBy_Username_Icontains: $username
  ) {
    totalCount
    edges {
      node {
        ...WorkloadRunFragment
      }
    }
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunsQuery = <
  TData = WorkloadRunsQuery,
  TError = unknown,
>(
  variables?: WorkloadRunsQueryVariables,
  options?: UseQueryOptions<WorkloadRunsQuery, TError, TData>
) => {
  return useQuery<WorkloadRunsQuery, TError, TData>(
    variables === undefined ? ['workloadRuns'] : ['workloadRuns', variables],
    useFetchData<WorkloadRunsQuery, WorkloadRunsQueryVariables>(
      WorkloadRunsDocument
    ).bind(null, variables),
    options
  )
}

export const WorkloadRuntimeCapacityDocument = `
    query WorkloadRuntimeCapacity($containerRuntimeId: ID!, $teamId: ID) {
  workloadRuntimeCapacity(
    containerRuntimeId: $containerRuntimeId
    teamId: $teamId
  ) {
    maximumScale
    singleInstanceCapacity
  }
}
    `

export const useWorkloadRuntimeCapacityQuery = <
  TData = WorkloadRuntimeCapacityQuery,
  TError = unknown,
>(
  variables: WorkloadRuntimeCapacityQueryVariables,
  options?: UseQueryOptions<WorkloadRuntimeCapacityQuery, TError, TData>
) => {
  return useQuery<WorkloadRuntimeCapacityQuery, TError, TData>(
    ['WorkloadRuntimeCapacity', variables],
    useFetchData<
      WorkloadRuntimeCapacityQuery,
      WorkloadRuntimeCapacityQueryVariables
    >(WorkloadRuntimeCapacityDocument).bind(null, variables),
    options
  )
}

export const WorkloadRunUpdateCreateDocument = `
    mutation workloadRunUpdateCreate($input: WorkloadRunUpdateCreateInput!) {
  workloadRunCreateUpdate(input: $input) {
    workloadRun {
      ...WorkloadRunFragment
    }
  }
}
    ${WorkloadRunFragmentFragmentDoc}`

export const useWorkloadRunUpdateCreateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    WorkloadRunUpdateCreateMutation,
    TError,
    WorkloadRunUpdateCreateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    WorkloadRunUpdateCreateMutation,
    TError,
    WorkloadRunUpdateCreateMutationVariables,
    TContext
  >(
    ['workloadRunUpdateCreate'],
    useFetchData<
      WorkloadRunUpdateCreateMutation,
      WorkloadRunUpdateCreateMutationVariables
    >(WorkloadRunUpdateCreateDocument),
    options
  )
}

export const KubeconfigDocument = `
    query kubeconfig {
  kubeconfig {
    kubeconfig
  }
}
    `

export const useKubeconfigQuery = <TData = KubeconfigQuery, TError = unknown>(
  variables?: KubeconfigQueryVariables,
  options?: UseQueryOptions<KubeconfigQuery, TError, TData>
) => {
  return useQuery<KubeconfigQuery, TError, TData>(
    variables === undefined ? ['kubeconfig'] : ['kubeconfig', variables],
    useFetchData<KubeconfigQuery, KubeconfigQueryVariables>(
      KubeconfigDocument
    ).bind(null, variables),
    options
  )
}
