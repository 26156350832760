import {
  SolidSelectorIcon as SelectorIcon,
  SolidSortAscendingIcon as SortAscendingIcon,
  SolidSortDescendingIcon as SortDescendingIcon,
} from './Icons'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table'
import { useRouter } from 'next/router'
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { UseQueryResult } from '@tanstack/react-query'

const DetailViewContainer = styled.div`
  margin: 1rem;
`

const OrderByButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
`

const TableRow = styled.tr`
  &:hover {
    background-color: ${({ theme }) => theme.colors.table.hoverBackground};
  }
`

const TableHeadCell = styled.th`
  background-color: ${({ theme }) => theme.colors.table.thBackground};
  border-radius: 5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
  border-width: 1px;
  padding: 4px;
  text-align: left;
  white-space: nowrap;
  min-width: 100px;
  :first-child {
    min-width: 20px;
    text-align: center;
  }
`

const TableBodyCell = styled.td`
  padding: 4px;
  white-space: nowrap;
`

const TableBodyCellContainer = styled.div`
  height: 22px;
  max-height: 22px;
  display: flex;
  gap: 4px;
  align-content: center;
  justify-content: left;
  &:hover .addFilterButton {
    color: ${({ theme }) => theme.colors.font.primary};
  }
`

const TableHead = styled.thead`
  border-radius: 5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
  border-width: 1px;
  background-color: ${({ theme }) => theme.colors.table.theadBackground};
`

const TableBody = styled.tbody``

const TableStyles = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${(props) =>
    props['aria-busy']
      ? props.theme.colors.table.loadingBackground
      : props.theme.colors.background};
`

const TableContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom-radius: 5px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
`
const TableDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
  border-width: 1px;
`

const IndeterminateCheckboxStyles = styled.input`
  cursor: pointer;
`

export function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & InputHTMLAttributes<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate])

  return <IndeterminateCheckboxStyles type="checkbox" ref={ref} {...rest} />
}

const TableStateIndicator = <TData,>(props: { data: TableState<TData> }) =>
  props.data.state === 'loading' ? (
    <p>Loading table data...</p>
  ) : props.data.state === 'error' ? (
    <p>{props.data.message}</p>
  ) : null

type TableStateLoading<TData> = {
  state: 'loading'
  rows: TData[]
}

type TableStateError<TData> = {
  state: 'error'
  message: string
  rows: TData[]
}

type TableStateReady<TData> = {
  state: 'ready'
  rows: TData[]
}

type TableState<TData> =
  | TableStateError<TData>
  | TableStateLoading<TData>
  | TableStateReady<TData>

export function queryResultToTableState<TData, Error>(
  query: UseQueryResult<unknown, Error>,
  rows: TData[]
): TableState<TData> {
  if (query.isFetching) {
    return { state: 'loading', rows }
  } else if (query.isError) {
    return {
      state: 'error',
      rows,
      message:
        (query.isLoadingError
          ? `Failed to load data: `
          : query.isRefetchError
            ? `Failed to refetch data: `
            : 'Error: ') + JSON.stringify(query.error),
    }
  } else {
    return { state: 'ready', rows }
  }
}

declare type TableProps<TData extends RowData> = {
  data: TableState<TData>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[]
  DetailView?: ({ rowSelection }: { rowSelection: TData[] }) => JSX.Element
}

export const Table = <R,>({ columns, data, DetailView }: TableProps<R>) => {
  const router = useRouter()
  const [rowSelection, setRowSelection] = useState({})

  const { getHeaderGroups, getRowModel, getSelectedRowModel } = useReactTable({
    data: data.rows,
    columns,
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  const rows = getRowModel().rows

  return (
    <TableDetailContainer>
      <TableContainer>
        <TableStyles aria-busy={data.state === 'loading'}>
          <TableHead>
            {getHeaderGroups().map((headerGroup, index) => (
              <TableRow key={`${headerGroup?.id}-${index}`}>
                {headerGroup?.headers?.map((header) => {
                  const orderBy = router?.query?.orderBy?.toString()
                  return (
                    <TableHeadCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {!header.column.id.startsWith('nonfilterable') && (
                            <OrderByButton
                              onClick={() => {
                                if (orderBy === header.id) {
                                  router.replace({
                                    query: {
                                      ...router.query,
                                      orderBy: '-' + header.id,
                                    },
                                  })
                                } else if (orderBy === '-' + header.id) {
                                  const clearOrderByQuery = router.query
                                  delete clearOrderByQuery['orderBy']
                                  router.replace({
                                    query: clearOrderByQuery,
                                  })
                                } else {
                                  router.replace({
                                    query: {
                                      ...router.query,
                                      orderBy: header.id,
                                    },
                                  })
                                }
                              }}
                            >
                              {orderBy === header.id ? (
                                <SortAscendingIcon />
                              ) : orderBy === '-' + header.id ? (
                                <SortDescendingIcon />
                              ) : (
                                <SelectorIcon />
                              )}
                            </OrderByButton>
                          )}
                        </div>
                      )}
                    </TableHeadCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.length === 0 && data.state !== 'loading' ? (
              <TableRow>
                <TableBodyCell colSpan={columns.length}>
                  No results
                </TableBodyCell>
              </TableRow>
            ) : (
              rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableBodyCell key={cell.id}>
                        <TableBodyCellContainer>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableBodyCellContainer>
                      </TableBodyCell>
                    )
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </TableStyles>
      </TableContainer>
      <TableStateIndicator data={data} />
      {DetailView && (
        <DetailViewContainer>
          <DetailView
            rowSelection={getSelectedRowModel().flatRows.map(
              (row) => row.original
            )}
          />
        </DetailViewContainer>
      )}
    </TableDetailContainer>
  )
}
